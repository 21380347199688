<template>
  <hover-icon class="help-text-icon" icon="circle-question">
    <slot>{{ helpText }}</slot>
  </hover-icon>
</template>
<script>
import HoverIcon from './HoverIcon.vue'

export default {
  name: 'HelpTextIcon',
  components: {
    HoverIcon
  },
  props: {
    helpText: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.help-text-icon {
  :deep(.popover) {
    --bs-popover-body-padding-x: .75rem;
    --bs-popover-body-padding-y: .75rem;
    --bs-popover-font-size: .85rem;
  }
}
</style>
