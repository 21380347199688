import restClient from '@/services/clients/rest'
import _ from 'lodash'
import reusePromise from 'reuse-promise'

async function get (resourceName, resourcePath) {
  try {
    const response = await restClient.get(resourcePath)
    return response.data
  } catch (error) {
    console.warn(`Error getting ${resourceName}`, error)
    throw error
  }
}
// wrapping get in reusePromise avoids load racing condition.
const getReusedPromise = reusePromise(get)

async function update (resourceName, resourcePath, data) {
  try {
    const response = await restClient.put(resourcePath, data)
    return response.data
  } catch (error) {
    console.warn(`Error updating ${resourceName}`, error)
    throw error
  }
}

export function registerSingletonResourceWithStore (resourceName, resourcePath, store, options) {
  if (store.state[resourceName]) throw new Error(`${resourceName} module already registered with store`)

  // Module state is always namespaced and complicates dirty detection,
  // so merge it explicitly.
  const { state: extendedState, ...extendedModule } = options || {}

  store.registerModule(resourceName, _.merge({
    namespaced: true,
    state () {
      return _.merge({
        loaded: false,
        originalData: {},
        formData: {},
        formInvalid: false
      }, extendedState && extendedState())
    },
    getters: {
      formDirty: state => {
        return !_.isEqual(state.originalData, state.formData)
      },
      // dirtyFields is for debugging purpose
      dirtyFields: (state, getters) => {
        const o1 = state.originalData
        const o2 = state.formData

        return _.reduce(o1, (result, value, key) =>
          _.isEqual(value, o2[key]) ? result : result.concat(key), [])
      }
    },
    actions: {
      async load (context, forceRefresh) {
        if (context.state.loaded && !forceRefresh) {
          return context.state.originalData
        }
        // Although wrapping in reusePromise avoids making multiple
        // remote calls, it doesn't avoid multiple vuex commits.
        // TODO: Avoid multiple commits in racing condition.
        const data = await getReusedPromise(resourceName, resourcePath)
        context.commit('load', data)
        return data
      },
      async update (context) {
        const result = await update(resourceName, resourcePath, context.state.formData)
        context.commit('update', result)
        return result
      },
      cancelChanges (context) {
        context.commit('cancelChanges', context.state.originalData)
      }
    },
    mutations: {
      load (state, data) {
        Object.assign(state, {
          originalData: data,
          formData: _.cloneDeep(data),
          loaded: true
        })
      },
      update (state, data) {
        state.originalData = data
        state.formData = _.cloneDeep(data)
      },
      cancelChanges (state) {
        state.formData = _.cloneDeep(state.originalData)
      },
      formInvalidChanged (state, formInvalid) {
        state.formInvalid = formInvalid
      },
      formDataChanged (state, formData) {
        state.formData = formData
      },
    }
  }, extendedModule))
}
