import _ from 'lodash'

export default function ({ filterDefaults, filterDefaultsDiffOmitKeys = [], searchAffectsActive = true }) {
  const canFilterActive = 'active' in filterDefaults()

  return {
    namespaced: true,
    state () {
      return {
        showingFilter: false,
        filterParams: filterDefaults(),
        searchInputText: null,
        filterFormData: {
          ...filterDefaults(),
          searchText: null
        }
      }
    },
    getters: {
      // filterDefaults must be used as a function by the caller, to avoid mutation
      filterDefaults: () => filterDefaults,
      // filterInUse only considers keys in filterDefaults. This intentionally ignores
      // searchText, because usually there is a search text box outside the filter.
      // We special case ignore active:'all', since it is inclusive of more results, and because we set that value when searching text.
      // filterAppearsInUse is used to color the Filter button. For that, we omit any custom filter values that are used outside the popover,
      // e.g., quick filter components.
      specifiedFilterInUse: (state, getters) => (filterKey, filterValue) =>
        !_.isEqual(filterValue, state.filterParams[filterKey]) && (filterKey !== 'active' || state.filterParams[filterKey] !== 'all'),
      filterAppearsInUse: (state, getters) =>
        Object.entries(getters.filterDefaults())
          .filter(pair => !filterDefaultsDiffOmitKeys.includes(pair[0]))
          .some(pair => !_.isEqual(pair[1], state.filterParams[pair[0]]) && (pair[0] !== 'active' || state.filterParams[pair[0]] !== 'all')),
      filterInUse: (state, getters) =>
        Object.entries(getters.filterDefaults())
          .some(pair => !_.isEqual(pair[1], state.filterParams[pair[0]]) && (pair[0] !== 'active' || state.filterParams[pair[0]] !== 'all')),
      filterOrSearchInUse: (state, getters) =>
        getters.filterInUse || state.filterParams.searchText || state.filterParams.active !== filterDefaults().active
    },
    actions: {
      setFilterParams (context, filterParams) {
        context.commit('setFilterParams', {
          filterParams,
          filterDefaults: context.getters.filterDefaults()
        })
      },
      show (context) {
        context.commit('show', context.getters.filterDefaults())
      },
      hide (context) {
        context.commit('hide')
      },
      applyForm (context) {
        context.commit('applyForm', context.getters.filterDefaults())
      },
      setSearchText (context, searchText) {
        if (canFilterActive && searchAffectsActive) {
          const activeParam = searchText && !context.getters.filterInUse ? 'all' : context.getters.filterDefaults().active
          context.commit('setSearchText', { searchText, activeParam })
        } else {
          context.commit('setSearchText', { searchText })
        }
      },
      clearForm (context) {
        context.commit('clearForm', context.getters.filterDefaults())
      }
    },
    mutations: {
      setFilterParams (state, { filterParams, filterDefaults }) {
        state.filterParams = Object.assign({}, _.fromPairs(
          Object.keys(filterDefaults).map(key =>
            [key, filterParams[key] === undefined ? filterDefaults[key] : filterParams[key]])))
        state.filterParams.searchText = state.searchInputText = filterParams.searchText
        // Also need to copy filter params into form data even before show mutation is called,
        // because a custom quick filter would need to be updated too.
        state.filterFormData = _.cloneDeep(state.filterParams)
      },
      show (state, filterDefaults) {
        // copy filter params into form data.
        // we need to do this for several reasons:
        // (1) search text can be changed outside filter
        // (2) user may have closed filter last time without applying
        // (3) filter params may have been set on route outside of filter
        Object.entries(filterDefaults)
          .forEach(([field, defaultValue]) => {
            const value = state.filterParams[field]
            // If value passed in is undefined, then use default value instead.
            // For most cases, the two values are equivalent.
            // The purpose is where a default value is non-empty, such
            // as defaulting a status field to 'active'.
            state.filterFormData[field] = value === undefined ? defaultValue : value
          })
        state.filterFormData.searchText = state.filterParams.searchText

        state.showingFilter = false
      },
      hide (state) {
        state.showingFilter = false
      },
      setSearchText (state, { searchText, activeParam }) {
        state.filterParams.searchText = state.filterFormData.searchText = state.searchInputText = searchText

        if (activeParam) {
          state.filterParams.active = state.filterFormData.active = activeParam
        }
      },
      applyForm (state, filterDefaults) {
        state.filterParams = Object.assign({}, state.filterParams, _.fromPairs(
          Object.keys(filterDefaults).map(key => [key, state.filterFormData[key]])))
        state.filterParams.searchText = state.searchInputText = state.filterFormData.searchText
      },
      clearForm (state, filterDefaults) {
        state.filterFormData = Object.assign({}, state.filterFormData, filterDefaults, { searchText: null })
      }
    }
  }
}
