<template>
  <div class="column-menu-button">
    <template v-if="primevue">
      <Button
        class="p-button-sm p-button-outlined
        p-button-secondary"
        label="Columns"
        :disabled="disabled"
        @click="$refs.op.toggle($event)"
      >
        <font-awesome-icon icon="columns" style="margin-right: .5rem;" />
        Columns
      </Button>
      <PVPopover ref="op" appendTo="body" :baseZIndex="1060">
        <column-menu
          :allColumns="allColumns"
          :label="label"
          :alternateLabel="alternateLabel"
          :columnLabelKey="columnLabelKey"
          :columnIsSelected="columnIsSelected"
          @selectedColumnsUpdated="$emit('selectedColumnsUpdated', $event)"
          @reset-to-defaults="$emit('reset-to-defaults')"
        />
      </PVPopover>
    </template>
    <template v-else>
      <b-button :id="`column-menu-${uid}`" class="button-minimize-on-sm" :variant="variant" :size="size" :disabled="disabled" @click="toggleColumnMenu">
        <font-awesome-icon icon="columns" />
        Columns
      </b-button>
      <Popover v-model:show="showColumnMenu" :target="`column-menu-${uid}`" placement="bottom" manual>
        <column-menu
          :allColumns="allColumns"
          :label="label"
          :alternateLabel="alternateLabel"
          :columnLabelKey="columnLabelKey"
          :columnIsSelected="columnIsSelected"
          @selectedColumnsUpdated="$emit('selectedColumnsUpdated', $event)"
          @reset-to-defaults="$emit('reset-to-defaults')"
        />
      </Popover>
    </template>
  </div>
</template>
<script>
import ColumnMenu from './ColumnMenu.vue'
import Popover from '@/components/Popover.vue'
import Button from 'primevue/button'
import PVPopover from 'primevue/popover'

export default {
  components: {
    Button,
    ColumnMenu,
    Popover,
    PVPopover
  },
  props: [
    'allColumns',
    'columnLabelKey',
    'columnIsSelected',
    'label',
    'alternateLabel',
    'variant',
    'size',
    'primevue',
    'disabled'
  ],
  emits: ['reset-to-defaults', 'selectedColumnsUpdated'],
  data () {
    return {
      uid: null,
      showColumnMenu: false
    }
  },
  methods: {
    toggleColumnMenu () {
      this.showColumnMenu = !this.showColumnMenu
    }
  },
  created () {
    this.uid = this.$.uid
  }
}
</script>
