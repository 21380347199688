<template>
  <div>
    <span v-if="loadedOnce">
      No Rows To Show
    </span>
    <b-btn @click="loadData" v-else>
      <font-awesome-icon icon="cloud-download-alt" />
      Load Data
    </b-btn>
  </div>
</template>
<script>
export default {
  name: 'NoRowsOverlapy',
  data () {
    return {
      unwatch: null,
      loadedOnce: false
    }
  },
  methods: {
    loadData () {
      this.params.loadHandler()
    }
  },
  created () {
    this.unwatch = this.params.parent.$watch(
      'loadedOnce',
      loadedOnce => this.loadedOnce = loadedOnce,
      { immediate: true }
    )
  },
  beforeDestroy () {
    if (this.unwatch) this.unwatch()
  }
}
</script>
