<template>
  <div class="audit-links">
    <div v-if="canViewAudit && instanceId">
      <div v-if="actorTitle"><router-link :to="actorLink" @click.native="$emit('link-opened')">
        {{ actorTitle }}
      </router-link></div>
      <div v-if="affectedTitle"><router-link :to="affectedLink" @click.native="$emit('link-opened')">
        {{ affectedTitle }}
      </router-link></div>
      <div v-if="associatedTitle"><router-link :to="associatedLink" @click.native="$emit('link-opened')">
        {{ associatedTitle }}
      </router-link></div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AuditLinks',
  props: {
    instanceId: Number,
    kind: String,
    actorTitle: String,
    affectedTitle: String,
    associatedTitle: String
  },
  computed: {
    ...mapGetters(['canViewAudit']),
    actorLink () {
      return {
        name: 'audit',
        params: {
          view: JSON.stringify({
            actor: {
              type: this.kind,
              id: this.instanceId
            }
          })
        }
      }
    },
    affectedLink () {
      return {
        name: 'audit',
        params: {
          view: JSON.stringify({
            affected: {
              kind: this.kind,
              id: this.instanceId
            }
          })
        }
      }
    },
    associatedLink () {
      return {
        name: 'audit',
        params: {
          view: JSON.stringify({
            associated: {
              kind: this.kind,
              id: this.instanceId
            }
          })
        }
      }
    }
  }
}
</script>
