import axios from 'axios'
import store from '@/store'

export function createMicroserviceClient (config) {

  if (typeof config === 'string') {
    config = { baseURL: config }
  }

  const instance = axios.create(config)

  function getBaseURL (orgMode) {
    if (['none', 'orgJwt'].includes(orgMode)) return config.baseURL
    const orgId = store.state.organizationId
    if (!orgId) {
      throw new Error('Can not make request without organization ID')
    }
    return `${config.baseURL}/orgs/${orgId}`
  }

  instance.interceptors.request.use(function (config) {
    return store.getters['jwt/orgAccessJwt']()
      .then(jwt => {
        config.headers.Authorization = `${store.getters['jwt/authHeaderPrefix']} ${jwt}`

        try {
          config.baseURL = getBaseURL(config.orgMode)
        } catch (error) {
          return Promise.reject(error)
        }

        return config
      })
  })

  return instance
}
