import constants from '@/constants'
import { initializeApp } from 'firebase/app'
import { getAuth, signOut as fSignOut } from 'firebase/auth'
import { getAnalytics, logEvent as fLogEvent } from 'firebase/analytics'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'
import notificationClient from '@/services/clients/notification'

import 'firebase/analytics'

const oConstants = constants()

const config = {
  apiKey: oConstants.firebaseApiKey,
  authDomain: oConstants.firebaseAuthDomain,
  projectId: oConstants.projectId,
  appId: oConstants.firebaseAppId,
  measurementId: oConstants.firebaseMeasurementId,
  messagingSenderId: oConstants.firebaseMessagingSenderId
}
const app = initializeApp(config)

export const auth = getAuth(app)
const analytics = getAnalytics(app)

export const logEvent = (eventName, eventParams) => fLogEvent(analytics, eventName, eventParams)
export const signOut = () => fSignOut(auth)

let _fcmInitialized = false
let _fcmToken = null
let _messaging = null

export function initFcm (store) {
  if (_fcmInitialized) {
    console.warn('FCM already initialized')
    return
  }
  if (!navigator.serviceWorker) {
    console.warn('Skipping FCM due to service worker API not supported')
    return
  }

  initFcmToken(store)

  _fcmInitialized = true
  console.log('FCM initialized')
}

// TODO: If user declines permission, will OS prompt user everytime app starts up, requiring us to keep state of that?
// TODO: If we failed to get token due to internet connectivity, do we need to call initToken again?
function initFcmToken (store) {
  store.watch(
    (state, getters) => getters.orgReady && getters.userNotificationsEnabled,
    userNotificationsEnabled => {
      if (!userNotificationsEnabled) return
      if (!_messaging) _messaging = getMessaging(app)

      requestPermission()
        // TODO: Fix sometimes after first time load, we get this error:
        // TODO: "Failed to execute 'subscribe' on 'PushManager': Subscription failed - no active Service Worker"
        .then(() => getToken(_messaging, { vapidKey: oConstants.firebaseVapidKey }))
        .then(async fcmToken => {
          if (fcmToken) {
            console.log('Retrieved fcm token', fcmToken)
            _fcmToken = fcmToken

            try {
              await notificationClient.post(
                `/orgs/${store.state.organizationId}/devices/${store.state.jwt.idaasClaims.fc_device}/register`,
                {
                  deviceType: 'WEB',
                  fcmToken
                }
              )
              console.log('Successfully registered fcm token with backend')
            } catch (error) {
              console.warn('Failed to register fcm tokn with backend', error)
              // We'll still move forward with listening for messages,
              // in case we had already previously registered.
              // TODO: Retry periodically rather than just wait until next page reload.
            }

            listenForMessages(store)

            // TODO: Do we need to listen for onTokenRefresh?

          } else {
            throw new Error('No fcm token returned')
          }
        })
        .catch(error => {
          console.warn('Failed to load fcm token', error)
        })

    },
    { immediate: true }
  )
}

function requestPermission () {
  return Notification.requestPermission()
    .then(permission => {
      if (permission !== 'granted') {
        throw new Error('Notification permission declined')
      }
      console.log('Notification permission granted')
    })
}

function listenForMessages (store) {
  // TODO: What happens if onMessage is called multiple times?
  // TODO: Do we need to make sure we only call it once?
  // TODO: De-dupe messages in case both handlers are called simultaneously.
  onMessage(_messaging, (payload) => {
    console.log('FCM foreground web push notification received. ', payload)
    handleMessage(payload, store)
  })

  const broadcast = new BroadcastChannel('fcm-background-message')
  broadcast.onmessage = async event => {
    console.log('Main thread received broadcast channel event from firebase messaging service worker', event)
    const [payload, tag] = event.data

    // First close notification created by service thread.
    await navigator.serviceWorker.ready.then(async registration => {
      await registration.getNotifications({ tag }).then((notifications) => {
        notifications.forEach(notification => notification.close())
      })
    })

    handleMessage(payload.data, store)
  }
}

async function handleMessage (payload, store) {
  const notificationMessage = JSON.parse(payload.data.data)

  if (!checkMessageValidity(notificationMessage, store)) return

  // Create notification pop-up when received in foreground.
  if (payload?.notification?.title) {
    const tag = notificationMessage.orgId ? `${notificationMessage.orgId}-${notificationMessage.id}` : notificationMessage.id.toString()
    // TODO: If it's a different organization, then display org name.
    const notification = new Notification(payload.notification.title, {
      body: payload?.notification?.body ?? '',
      icon: `${location.origin}/img/logo-symbol.png`,
      tag
    })
    notification.onclick = event => {
      if (store.getters.insideOrgNamespace && store.state.organizationId === notificationMessage.orgId) {
        window.$vm0.$router.push(`/orgs/${notificationMessage.orgId}/notifications`)
        window.focus()
      } else {
        window.open(`${location.origin}/orgs/${notificationMessage.orgId}/notifications`, '_blank')
      }
    }
  }

  store.dispatch('dashboardNotifications/addNotificationMessage', notificationMessage)
}

function checkMessageValidity (notificationMessage, store) {

  if (notificationMessage.orgUserId === store.state.orgUserId) {
    console.log('Notification message matches org user')
    return true
  }

  if (notificationMessage.userId && notificationMessage.userId === store.state.userProfile.userId) {
    console.log('Notification message matches user')
    return true
  }

  console.log('Notification message does not match org user or user')

  return false
}
