import restClient from '@/services/clients/rest'
import { hasValue } from '@/utils/misc'
import { get } from 'lodash'

async function _list (url, searchText, cursor, limit, active, additionalParams) {
  // If we're searching by id without an active preference, then include all.
  if ((active === null || active === undefined) && hasValue(get(additionalParams, 'id'))) {
    active = 'all'
  }

  try {
    const response = await restClient.get(url, {
      params: {
        searchText,
        active,
        cursor,
        limit,
        ...additionalParams
      }
    })
    return response.data
  } catch (error) {
    console.warn('Error getting employee selection list', error)
    throw error
  }
}

export default {
  // Both endpoints only return workers (not non-worker admins)
  async list (searchText, cursor, limit, active, additionalParams) {
    const url = 'employees/readonly'
    const data = await _list(url, searchText, cursor, limit, active, additionalParams)
    return data
  },
  async listAllowedForOrgUnit(orgUnitId, searchText, limit, active) {
    const url = `orgunits/${orgUnitId}/allowed-employees`
    return await _list(url, searchText, null, limit, active)
  }
}
