import restClient from '@/services/clients/rest'
import _ from 'lodash'

export default {
  async retrieveFaceFailure (direction, date) {
    try {
      const response = await restClient.get('dashboard/face-failure', {
        params: {
          [direction]: date
        }
      })
      return _.get(response, 'data.faceFailure')
    } catch (error) {
      console.warn('Error retrieving face failure', error)
      throw error
    }
  },
  async decideFaceFailure (clockLogId, decision, employeeId) {
    const params = employeeId ? { employee: employeeId } : undefined

    try {
      const response = await restClient.post(`dashboard/face-failure/${clockLogId}/${decision}`, params)
      return response.data
    } catch (error) {
      console.warn('Error deciding face failure', error)
      throw error
    }
  }
}
