import _ from 'lodash'
import { printWindow } from '@/utils/print'
import { promptPdfExportDialog } from '../components/report/pdf-export-dialog'
import PrintModal from '../components/report/PrintModal.vue'
import { mapGetters, mapState } from 'vuex'

const EXCEL_BORDER = { color: '#ededed', weight: 1, lineStyle: 'Continuous' }
const EXCEL_BORDERS = {
  borderBottom: EXCEL_BORDER,
  borderLeft: EXCEL_BORDER,
  borderRight: EXCEL_BORDER,
  borderTop: EXCEL_BORDER
}

export default {
  // ManagesGridColumns is a peer mixin dependenfcy
  data () {
    return {
      isPrinting: false,
      gridApi: null, // mixing component should set this value

    }
  },
  computed: {
    ...mapGetters('formatPreferences', ['excelDurationNumberFormat', 'excelPayNumberFormat']),
    ...mapState('formatPreferences', {
      durationFormat: state => state.originalData.durationFormat
    }),
    fileName () {
      return this.exportFilename || this.reportTitle || 'Export'
    },
    excelStyles () {
      return [
        {
          id: 'cell',
          borders: EXCEL_BORDERS
        },
        {
          id: 'title',
          alignment: {
            horizontal: 'Center'
          },
          font: {
            size: 14,
            bold: true
          }
        },
        {
          id: 'subtitle',
          alignment: {
            horizontal: 'Center'
          },
          font: {
            size: 12,
            bold: true
          }
        },
        {
          id: 'header',
          font: {
            size: 12,
            bold: true
          },
          borders: EXCEL_BORDERS
        },
        {
          id: 'headerGroup',
          font: {
            size: 12,
            bold: true
          },
          interior: {
            color: "#FAFAF8", pattern: 'Solid'
          }
        },
        {
          id: 'footerCell',
          interior: {
            color: "#FAFAF8", pattern: 'Solid'
          },
          font: {
            bold: true
          }
        },
        ..._.range(1, 20).map(i => ({
          id: `group-row-${i}`,
          alignment: {
            indent: i
          },
          dataType: 'String'
        })),
        {
          id: 'duration',
          alignment: {
            horizontal: 'Right',
            indent: 0
          },
          ...(
            this.durationFormat === 'minute'
              ? { dataType: 'String' }
              : {
                dataType: 'Number',
                numberFormat: {
                  format: this.excelDurationNumberFormat()
                }
              }
          )
        },
        {
          id: 'pay',
          numberFormat: {
            format: this.excelPayNumberFormat
          }
        }
      ]
    }
  },
  methods: {
    getExportColumnFields () {
      return this.gridApi.getAllDisplayedColumns()
        .filter(column => !column.colDef.hide && !column.colDef.context?.hideInExport)
        .map(column => column.colDef.field)
    },
    print () {
      const rowGroupColumns = this.gridApi.getRowGroupColumns()
      const outerGroupName = !this.gridApi.isPivotMode() && rowGroupColumns.length ? rowGroupColumns[0].colDef.headerName : null

      this.showModal({
        component: PrintModal,
        props: {
          outerGroupName,
          onOk: printPreferences => {
            this.printWithPreferences({ ...printPreferences })
          }
        }
      })
    },

    async printWithPreferences (options, headless) {
      // Throws error in headless mode when trying to print an empty grid.
      if (headless && !this.gridApi.isPivotMode() && !this.gridApi.getDisplayedRowCount()) {
        return Promise.reject('There is no data to print.')
      }

      await this.enterPrintMode(options, headless)

      const afterPrint = () => {
        this.exitPrintMode()
        this.$emit('exported', { type: 'print', data: this.gridApi.getGridOption('rowData') })
      }

      if (headless) {
        // In headless mode, return the print window so it can be rendered to pdf.
        const pw = window.print
        window.print = () => {
          pw()
          window.print = pw
          afterPrint()
        }
        return { window, title: this.reportTitle }
      } else {
        // await new Promise(resolve => setTimeout(resolve, 1000))
        await printWindow(window)
        afterPrint()
      }
    },

    csv (csvFlat = false) {
      // TODO: Support iOS download -
      // TODO: https://www.ag-grid.com/javascript-grid-export/#export-to-csv-with-ipad
      const fileName = `${this.fileName}.csv`
      const pivotMode = this.gridApi.isPivotMode()

      if (csvFlat && this.groupColumns.length) {
        // TODO: Fix for when there are pivot columns.
        const columnKeys = [
          ...this.gridApi.getRowGroupColumns().map(col => col.colId),
          ...this.gridApi.getAllDisplayedColumns().filter(col => col.colId !== 'ag-Grid-AutoColumn')/*.filter(col => col.aggregationActive)*/.map(col => col.colId)
        ]
        this.gridApi.exportDataAsCsv({
          columnKeys,
          fileName,
          shouldRowBeSkipped: params => {
            // console.log('shouldRowBeSkipped', params)
            return pivotMode ? !params.node.leafGroup : params.node.group || params.node.footer
          },
          processCellCallback: params => {
            // console.log('processCellCallback', params)
            // TODO: Messy and fragile!
            const groupRowValueFormatter = params.column.colDef.context?.groupRowValueFormatter
            if (groupRowValueFormatter) {
              return groupRowValueFormatter({ node: params.node, colDef: params.column.colDef, data: params.node.data })
            }
            const value = params.formatValue(params.value)
            if (value) return value

            const field = params.column.colId
            let cursor = params.node
            do {
              if (cursor.field === field) {

                return cursor.groupData['ag-Grid-AutoColumn']
              }
              cursor = cursor.parent
            } while (cursor)
            return '' // unexpected

            // We tried this previously. Not sure if we'll need to reference it again later.
            // if (pivotMode || params.column.aggregationActive) {
            //   return params.formatValue(params.value)
            // } else {
            //   const groupRowValueFormatter = params.column.colDef.context?.groupRowValueFormatter
            //   if (groupRowValueFormatter) {
            //     return groupRowValueFormatter({ node: params.node, colDef: params.column.colDef, data: params.node.data })
            //   }

            //   const field = params.column.colId
            //   let cursor = params.node
            //   do {
            //     if (cursor.field === field) {

            //       return cursor.groupData['ag-Grid-AutoColumn']
            //     }
            //     cursor = cursor.parent
            //   } while (cursor)
            //   return '' // unexpected
            // }
          }
        })
      } else {
        this.gridApi.exportDataAsCsv({
          // TODO: ag-grid has indeterministic behavior when specifying columnKeys.
          // TODO: Sometimes the csv returned is blank. I don't know why.
          columnKeys: this.gridApi.isPivotMode() ? undefined : this.getExportColumnFields(),
          fileName
        })
      }

      this.$emit('exported', { type: csvFlat ? 'csvFlat': 'csv', data: this.gridApi.getGridOption('rowData') })
      return Promise.resolve(fileName)
    },

    csvFlat () {
      this.csv(true)
    },

    excel () {
      const exportColumnFields = this.gridApi.isPivotMode() ? undefined : this.getExportColumnFields()
      const columnCount = (exportColumnFields || this.gridApi.getAllDisplayedColumns()).length
      const fileName = `${this.fileName}.xlsx`
      this.gridApi.exportDataAsExcel({
        columnKeys: exportColumnFields,
        fileName,
        sheetName: 'Sheet1',
        prependContent: [
          {
            cells: [
              {
                data: {
                  value: this.reportTitle,
                  type: 'String'
                },
                mergeAcross: columnCount - 1,
                styleId: 'title'
              }
            ]
          },
          ...(this.subtitles ?? []).map(subtitle => (
            {
              cells: [
                {
                  data: {
                    value: subtitle,
                    type: 'String'
                  },
                  mergeAcross: columnCount - 1,
                  styleId: 'subtitle'
                }
              ]
            }
          )),
          {
            cells: []
          }
        ]
      })
      this.$emit('exported', { type: 'xlsx', data: this.gridApi.getGridOption('rowData') })
      return Promise.resolve(fileName)
    },

    pdf () {
      // TODO: Should there be option to fit width to page, or do we always do that?
      const rowGroupColumns = this.gridApi.getRowGroupColumns()
      const outerGroupName = !this.gridApi.isPivotMode() && rowGroupColumns.length ? rowGroupColumns[0].colDef.headerName : null

      promptPdfExportDialog(this, { showFitWidthToPage: false, outerGroupName })
        .then(exportPreferences => this.pdfWithPreferences(exportPreferences))
        .catch(() => {
          // user cancelled
        })
    },

    pdfWithPreferences (options) {
      const fileName = `${this.fileName}.pdf`
      return import('@/pdfExport/gridExporter')
        .then(module => {
          const exportToPDF = module.exportToPDF
          return exportToPDF({
            gridApi: this.gridApi,
            fileName,
            pageOrientation: options.pageOrientation?.toLowerCase?.() ?? 'portrait',
            includeTitles: options.includeTitles,
            repeatTitles: options.repeatTitles,
            pageBreakPerGroup: options.pageBreakPerGroup,
            title: this.reportTitle,
            subtitles: this.subtitles ?? []
          })
        })
        .then(() => {
          this.$emit('exported', { type: 'pdf', data: this.gridApi.getGridOption('rowData') })
          return Promise.resolve(fileName)
        })
        .catch(error => {
          // TODO: Show error message if not headless.
          console.warn('Failed to export pdf', error)
          throw error
        })
    },

    async enterPrintMode (options, headless) {
      this.isPrinting = true

      document.body.classList.add('printing-page')

      // Set css zoom based on pref. In headless mode, the report service
      // sets it instead as a pdf scale option.
      // https://stackoverflow.com/questions/28757370/scale-html-table-before-printing-using-css
      // TODO: Explore using -moz-transform: scale() on Firefox
      // TODO: because Firefox doesn't support css zoom.
      this._body_zoom = document.body.style.zoom
      if (!headless && Number.isInteger(options.scale)) {
        document.body.style.zoom = `${options.scale}%`
      }

      this.buildPrintLayout(options)

      this.gridApi.setGridOption('domLayout', 'print')

      return new Promise(resolve => {
        // TODO: Try to use ag-grid's AnimationQueueEmptyEvent instead of polling.
        // TODO: Disable export button while we're waiting here.
        const checkAnimationFrameQueue = () => {
          if (this.gridApi.isAnimationFrameQueueEmpty()) {
            // Documentation sample waits 2 seconds:
            // https://www.ag-grid.com/javascript-grid-for-print/#example-for-print-complex
            setTimeout(resolve, 2000)
          } else {
            setTimeout(checkAnimationFrameQueue, 100)
          }
        }
        checkAnimationFrameQueue()
      })
    },

    buildPrintLayout (options) {
      // https://github.com/ag-grid/ag-grid/issues/2814#issuecomment-1126025009
      const agGrid = this.gridApi.getModel().beans.eGridDiv
      const agGridHeader = agGrid.querySelector('.ag-header')
      const agGridBody = agGrid.querySelector('.ag-body-viewport')
      const agGridFooter = agGrid.querySelector('.ag-floating-bottom')
      if (!agGridHeader || !agGridBody) return

      const table = document.createElement('table')

      // When printing ag-grid with pageBreakPerGroup, <table> element must be visible root;
      // so we can't print non-repeating titles.
      let printGridWrapper = null
      if (options.includeTitles && !options.pageBreakPerGroup) {
        printGridWrapper = document.createElement('div')
        printGridWrapper.appendChild(table)
      } else {
        printGridWrapper = table
      }

      printGridWrapper.classList.add('ag-theme-balham', 'printable', 'print-grid-wrapper')
      if (options.pageBreakPerGroup) {
        printGridWrapper.classList.add('page-break-per-group')
      }

      // If rows have an auto-height column, then we must use visibility:hidden instead of display:none.
      // Otherwise, the rows won't properly calculate height.
      const autoHeight = this.gridApi.getAllDisplayedColumns().some(col => col.colDef.autoHeight)
      printGridWrapper.classList.add(autoHeight ? 'auto-height' : 'no-auto-height')

      // Insert immediately after agGrid, so we can later put agGrid back in the same place in sibling list.
      agGrid.insertAdjacentElement('afterend', printGridWrapper)

      const thead = document.createElement('thead')

      if (options.includeTitles && this.reportTitle) {
        const titlesHtml = `
          <div class="printed-titles">
            <div class="title">${this.reportTitle || ''}</div>
            <div class="subtitles">
              ${(this.subtitles ?? []).map(subtitle => `<div class="subtitle">${subtitle}</div>`).join('\n')}
            </div>
          </div>
        `
        if (options.repeatTitles || options.pageBreakPerGroup) {
          thead.insertAdjacentHTML('afterbegin', `<tr><th>${titlesHtml}</th></tr>`)
        } else {
          printGridWrapper.insertAdjacentHTML('afterbegin', titlesHtml)
        }
      }

      // Move grid header columns into thead.
      const tr = document.createElement('tr')
      const th = document.createElement('th')
      tr.appendChild(th)
      thead.appendChild(tr)

      th.appendChild(agGridHeader)

      table.appendChild(thead)

      // body
      const tbody = document.createElement('tbody')
      const tr2 = document.createElement('tr')
      const td = document.createElement('td')

      tr2.appendChild(td)
      tbody.appendChild(tr2)

      td.appendChild(agGridBody)
      table.appendChild(tbody)

      // Footer
      if (agGridFooter) {
        const tfoot = document.createElement('tfoot')
        const tr3 = document.createElement('tr')
        const td2 = document.createElement('td')
        tr3.appendChild(td2)
        tfoot.appendChild(tr3)
        td2.appendChild(agGridFooter)
        table.appendChild(tfoot)
      }
    },

    destroyPrintLayout () {

      const agGrid = this.gridApi.getModel()?.beans?.eGridDiv
      if (!agGrid) return // user probably navigated away, and this component was already unmounted

      const printGridWrapper = agGrid.parentNode.querySelector('.print-grid-wrapper')
      if (!printGridWrapper) {
        console.warn('[destroyPrintLayout] print-grid-wrapper element not found')
        return
      }

      // Insert back in original position in sibling list.
      printGridWrapper.insertAdjacentElement('beforebegin', agGrid)

      const agGridHeader = printGridWrapper.querySelector('.ag-header')
      const agBodyViewport = printGridWrapper.querySelector('.ag-body-viewport')
      const agGridFooter = printGridWrapper.querySelector('.ag-floating-bottom')

      const agRoot = agGrid.querySelector('.ag-root')
      agRoot.prepend(agGridHeader)

      const agBody = agGrid.querySelector('.ag-body')
      agBody.prepend(agBodyViewport)

      if (agGridFooter) {
        agRoot.querySelector('.ag-sticky-bottom').insertAdjacentElement('afterend', agGridFooter)
      }

      printGridWrapper.remove()
    },

    exitPrintMode () {

      this.destroyPrintLayout()
      document.body.classList.remove('printing-page')
      document.body.style.zoom = this._body_zoom
      this.gridApi.setGridOption('domLayout', undefined)

      this.isPrinting = false
    }
  }
}
