
<template>
  <div class="spinner-container" :style="{height}">
    <slot name="header"></slot>
    <font-awesome-icon icon="circle-notch" spin class="spinner" :style="{marginTop: centerVOffset}" />
    <slot name="footer"></slot>
  </div>
</template>

<script>
export default {
  props: {
    centerVOffset: {
      type: String,
      default: '0px'
    },
    height: {
      type: String,
      default: 'auto'
    }
  }
}
</script>

<style lang="scss" scoped>

.spinner-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #666;

  .spinner {
    font-size: 24px;
    color: #777;
  }
}
</style>
