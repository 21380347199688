import { registerListResourceWithStore } from '@/services/GenericListResourceService'
import _ from 'lodash'
import restClient from '@/services/clients/rest'

async function setMode (punchCodeMode) {
  try {
    await restClient.put('settings', { punchCodeMode })
  } catch (error) {
    console.warn('Error setting punch code mode', error)
    throw error
  }
}

export function registerPunchCodesWithStore (store) {

  registerListResourceWithStore('punchCodes', 'punch-codes', store, {
    getters: {
      punchCodeMode (state, getters, rootState) {
        return rootState.punchCodeMode
      },
      sortedPunchCodes (state) {
        return _.sortBy(state.items, 'name')
      },
      sortedInPunchCodes (state, getters) {
        return getters.sortedPunchCodes.filter(punchCode => ['in', 'both'].includes(punchCode.type))
      },
      sortedOutPunchCodes (state, getters) {
        return getters.sortedPunchCodes.filter(punchCode => ['out', 'both'].includes(punchCode.type))
      }
    },
    actions: {
      updateMode (context, punchCodeMode) {
        const oldValue = context.getters.punchCodeMode
        context.dispatch('updateSession', { punchCodeMode }, { root: true })
        return setMode(punchCodeMode)
          .catch(error => {
            // undo
            context.dispatch('updateSession', { punchCodeMode: oldValue }, { root: true })
            return Promise.reject(error)
          })
      }
    }
  })
}
