<template>
  <!--
  TODO: Bootstrap-vue seems to have changed BFormGroup dom in version 25.7!
  https://github.com/bootstrap-vue-next/bootstrap-vue-next/commit/07daa80aef41df5b8e31578c2bc695823c2f6510
  https://github.com/bootstrap-vue-next/bootstrap-vue-next/issues/2312
  -->
  <b-form-group
    class="form-group"
    :invalid-feedback="firstErrorMessage"
    :state="isValid"
    :label="label"
    :label-class="labelClass"
    :label-cols="labelCols"
    :label-cols-sm="labelColsSm"
    :label-cols-md="labelColsMd"
    :label-cols-lg="labelColsLg"
    :label-cols-xl="labelColsXl"
    :label-for="labelFor"
    :description="description"
  >
    <template v-for="(_, name) in $slots" #[name]="slotData">
        <slot v-if="name === 'default'" v-bind="{ state: isValid, onInput: () => validator?.$touch?.() }" />
        <slot v-else :name="name" v-bind="slotData" />
    </template>
  </b-form-group>
</template>
<script>
export default {
  name: 'FormGroup',
  // TODO: Why can't we just use $attrs.labelCols?
  props: [
    'validator',
    'listItemIndex',
    'listItemProperty',
    'label',
    'labelFor',
    'labelClass',
    'labelCols',
    'labelColsSm',
    'labelColsMd',
    'labelColsLg',
    'labelColsXl',
    'messages',
    'description'
  ],
  computed: {
    isValid () {
      const invalid = this.validatorData?.$invalid
      return invalid === true || invalid === false ? !invalid : null
    },
    isListItem () {
      return Number.isInteger(this.listItemIndex)
    },
    firstErrorMessage () {
      // TODO: Do we need to check messages prop?
      return this.validatorErrors?.[0]?.$message ?? null
    },
    validatorData () {
      if (this.isListItem) {
        const item = this.validator?.$each?.$response?.$data?.[this.listItemIndex]
        return this.listItemProperty ? item?.[this.listItemProperty] : item
      } else {
        return this.validator
      }
    },
    validatorErrors () {
      if (this.isListItem) {
        const item = this.validator?.$each?.$response?.$errors?.[this.listItemIndex]
        return this.listItemProperty ? item?.[this.listItemProperty] : item
      } else {
        return this.validator?.$errors
      }
    }
  },
  mounted () {
    // TODO: Do we still need this in Vue 3?
    this.validator?.$touch?.()
  }
}
</script>

<style lang="scss" scoped>
// https://github.com/bootstrap-vue-next/bootstrap-vue-next/issues/2039
.form-group {
  margin-bottom: 1rem;
}
</style>
