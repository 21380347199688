
// Mixin to form components that are typically provided to DetailForm's default scope.
export default {
  props: {
    originalData: Object,
    supplementalData: Object,
    formData: Object,
    formDirty: Boolean,
    isFormOpen: Boolean,
    eventBus: Object,
    detailUpdated: Function,
    detailIndex: Number,
    formDataChanged: Function,
    formInvalidChanged: Function,
    addingNew: Boolean,
    saving: Boolean,
    applyGridTransaction: Function,
    exit: Function
  }
}
