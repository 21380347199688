import _ from 'lodash'
import { hasValue, isNumeric } from './misc'

const BLANK_VALUE = '<Blank>'

export function getCustomFieldNames(customFields, supplementalData) {

  const entries = (customFields || [])
    .map(customField => {
      switch (customField.type) {
        case 'cost_code':
          return ['costCodeName', supplementalData.costCode[customField.value].name]
        case 'job_phase':
          return ['jobPhaseName', supplementalData.jobPhase[customField.value].name]
      }
    })

  return Object.fromEntries(entries)
}

export function findCustomFieldIdsOfType(data, customFieldType) {
  // data looks like this:
  /*
    {
      'customField.type': ['job_category', 'cost_code'],
      'customField.value: [123, 456]
    }
  */
  const values = data['customField.value']
  return (data['customField.type'] || [])
    .flatMap((type, index) => type === customFieldType ? [values[index]] : [])
}

export function updateCustomFieldsOfType(data, customFieldType, ids) {
  ids = ids || []
  const types = data['customField.type']
  const values = data['customField.value']
  const otherCustomFieldIndexes = (types || [])
    .flatMap((type, index) => type !== customFieldType ? [index] : [])
  data['customField.type'] = otherCustomFieldIndexes
    .map(index => types[index])
    .concat(new Array(ids.length).fill(customFieldType))
  data['customField.value'] = otherCustomFieldIndexes
    .map(index => values[index])
    .concat(ids)
}

export function getSupplementalUserCustomFieldValues(workerId, supplementalData) {
  return _.get(supplementalData, `orgUser.${workerId}.customFieldValues`)
}

export function getSupplementalUserCustomFieldValue(customField, workerId, supplementalData) {
  const customFieldValues = getSupplementalUserCustomFieldValues(workerId, supplementalData)
  return getCustomFieldValue(customField, customFieldValues, supplementalData)
}

export function getCustomFieldValue(customField, customFieldValues, supplementalData) {
  const value = (customFieldValues || []).find(v => v.field === customField.id)
  let displayValue = value ? value.value : null
  // We won't convert empty value for a number type field to 0.
  // Reports that do aggregation will need to convert empty number values to 0.
  if (hasValue(displayValue) || _.isNumber(displayValue)) {
    if (value.type === 'bool') {
      displayValue = displayValue === true ? (customField.boolTrueLabel || 'YES') : (customField.boolFalseLabel || 'NO')
    } else if (value.type === 'number' && isNumeric(displayValue)) {
      displayValue = parseFloat(displayValue)
    } else if (value.type === 'choice') {
      const values = _.isArray(displayValue) ? displayValue : [displayValue]
      const names = values.flatMap(value => {
        const customFieldItem = _.get(supplementalData, `customFieldItem.${value}`)
        return customFieldItem ? [customFieldItem.name] : []
      })
      displayValue = names.join(', ')
    }
  }
  return displayValue
}

export function useCustomFieldPivotValue (customFieldValue, customField) {
  if (customField.type === 'number') {
    if (!customFieldValue) return 0
  } else if (customFieldValue === null || customFieldValue === undefined || customFieldValue === '') {
    return BLANK_VALUE
  }
  return customFieldValue
}
