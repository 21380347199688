import restClient from '@/services/clients/rest'

const moduleName = 'userProfile'

async function get (resourceName) {
  try {
    const response = await restClient.get('user-profile', { orgMode: 'none' })
    return response.data
  } catch (error) {
    console.warn(`Error getting ${resourceName}`, error)
    throw error
  }
}

export function registerUserProfileWithStore (store) {
  if (store.state[moduleName]) throw new Error(`${moduleName} module already registered with store`)

  // TODO: Reconcile with jwt/user. Perhaps the difference is
  // TODO: that userProfile is fareclock user information?
  // TODO: So what do we need from that?
  store.registerModule(moduleName, {
    namespaced: true,
    state () {
      return {
        loaded: false,
        userId: null,
        userName: null,
        emailAddress: null,
        emailVerified: false,
        userAuthProvider: null,
        sudoType: null,
        isAppAdmin: false,
        isDistributorAdmin: false,
        distributor: null,
        countryCode: null,
        userNotificationsEnabled: false
      }
    },
    getters: {
      isSudoSuper: state => state.sudoType === 'super',
      isSudoUser: state => !!state.sudoType,
      isSudoAccessToAllOrgs: state => ['staff', 'super'].includes(state.sudoType),
      isWhiteLabel: state => !!state.distributor,
      isWhiteLabelNotAdmin: (state, getters) => getters.isWhiteLabel && !state.isDistributorAdmin
    },
    actions: {
      async load (context, forceRefresh) {
        if (context.state.loaded && !forceRefresh) return // successful promise
        const data = await get()
        context.commit('load', data)
        return data
      }
    },
    mutations: {
      load (state, data) {
        Object.assign(state, data)
        state.loaded = true
      }
    }
  })
}
