import { extractErrorMessage } from '@/utils/misc'

export default {
  data () {
    return {
      // maskSynced is used to clear timer when maskOptions is directly mutated
      maskSynced: false,
      maskOptions: false
    }
  },
  watch: {
    maskOptions (newVal) {
      if (!this.maskSynced) {
        this.clearTimer()
      } else {
        this.maskSynced = false
      }
    }
  },
  methods: {
    setMaskOptions (maskOptions) {
      this.clearTimer()
      this.maskSynced = true
      this.maskOptions = maskOptions
    },
    showTimedMask (maskOptions, duration) {
      this.setMaskOptions(Object.assign({ clearOnClick: true }, maskOptions))
      this.timerId = setTimeout(() => {
        this.maskOptions = false
      }, duration)
    },
    showSpinningMask (msg) {
      this.setMaskOptions({ mode: 'updating', text: msg })
    },
    showLoadingMask () {
      this.setMaskOptions({ mode: 'loading' })
    },
    showUpdatingMask () {
      this.setMaskOptions({ mode: 'updating' })
    },
    showErrorMask (error) {
      const msg = error instanceof Error ? extractErrorMessage(error) : error
      this.showTimedMask({ mode: 'error', text: msg }, 5000)
    },
    showInfoMask (msg) {
      this.showTimedMask({ mode: 'info', text: msg }, 5000)
    },
    hideMask () {
      this.setMaskOptions(false)
    },
    clearTimer () {
      if (this.timerId) {
        clearTimeout(this.timerId)
        this.timerId = null
      }
    }
  }
}
