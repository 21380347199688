import restClient from '@/services/clients/rest'

export default {
  async submitFeedback (rating, comments) {
    try {
      await restClient.post(`feedback`, { rating, comments }, { jwtType: 'idaasJwt', orgMode: 'optional' })
    } catch (error) {
      console.warn('Error submitting feedback', error)
      throw error
    }
  }
}
