import restClient from '@/services/clients/rest'
import _ from 'lodash'

// TODO: Refactor with GenericListResourceService, which attaches
// TODO: a crud service to vuex.

export function createCrudService (client, resourceName, resourcePath) {
  return {
    async get (itemId) {
      try {
        const response = await client.get(`${resourcePath}/${itemId}`)
        return response.data
      } catch (error) {
        console.warn(`Error getting ${resourceName} ${itemId}`, error)
        throw error
      }
    },
    async list (params, config) {
      try {
        const response = await client.get(resourcePath, { params, ...config })
        return response.data
      } catch (error) {
        console.warn(`Error getting ${resourceName} list`, error)
        throw error
      }
    },
    async create (item, config) {
      try {
        const response = await client.post(resourcePath, item, config)
        return response.data
      } catch (error) {
        console.warn(`Error creating ${resourcePath}`, error)
        throw error
      }
    },
    async update (item, config) {
      try {
        const response = await client.put(`${resourcePath}/${item.id}`, item, config)
        return response.data
      } catch (error) {
        console.warn(`Error updating ${resourceName} ${item.id}`, error)
        throw error
      }
    },
    async delete (itemId, config) {
      try {
        // See comment in MasterDetailTable.deleteItem method why we have
        // to check here that config is an object.
        config = _.isPlainObject(config) ? config : null
        const response = await client.delete(`${resourcePath}/${itemId}`, config)
        return response.data
      } catch (error) {
        console.warn(`Error deleting ${resourceName} ${itemId}`, error)
        throw error
      }
    }
  }
}

export function createRestCrudService (resourceName, resourcePath) {
  return createCrudService (restClient, resourceName, resourcePath)
}
