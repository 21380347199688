
const idleTimeout = 1000 * 60 // one minute

const activityEvents = [
  'keydown', 'mousedown', 'touchstart', 'touchmove', 'mousemove', 'scroll']

function initActivityMonitor (store) {
  store.registerModule('idle', {
    namespaced: true,
    state () {
      return {
        idle: false
      }
    },
    actions: {
      wake (context) {
        context.commit('wake')
      },
      sleep (context) {
        context.commit('sleep')
      }
    },
    mutations: {
      wake (state) {
        state.idle = false
      },
      sleep (state) {
        state.idle = true
      }
    }
  })

  // unfortunately, vuex doesn't currently have a lifecycle.
  // so we'll just do our initialization here, without any teardown.
  // hopefully in the future we can add created/destroyed hooks:
  // https://github.com/vuejs/vuex/issues/467

  let timerId = null

  function enterIdleState () {
    if (store.state.idle.idle) {
      // sort of unexpected
      return
    }

    store.dispatch('idle/sleep')
  }

  function activityDidOccur () {
    if (timerId) clearTimeout(timerId)

    if (store.state.idle.idle) {
      store.dispatch('idle/wake')
    }

    timerId = setTimeout(enterIdleState, idleTimeout)
  }

  for (const event of activityEvents) {
    document.addEventListener(event, activityDidOccur)
  }

  activityDidOccur() // need to call once to set timeout
}

export {
  initActivityMonitor
}
