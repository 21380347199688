import restClient from '@/services/clients/rest'
import _ from 'lodash'

export default {
  async retrievePayPeriodActivity (startDate, endDate, clockLogFailures) {
    try {
      const response = await restClient.get('dashboard/period-activity', {
        params: {
          startDate,
          endDate,
          clockLogFailures: clockLogFailures ? 'yes' : 'no'
        }
      })
      return _.get(response, 'data.periodActivity')
    } catch (error) {
      console.warn('Error retrieving pay period activity', error)
      throw error
    }
  }
}
