<template>
  <div class="costing-selection">
    <form-group
      :validator="v$.value[jobLabelAttribute]"
      v-if="jobLabelEnabled"
      label="Job label"
      class="job-label"
      v-bind="formGroupConfig"
    >
      <template #default="slotProps">
        <label-select
          v-bind="slotProps"
          v-model="value[jobLabelAttribute]"
          :multiple="false"
          labelType="work"
          :disabled="disabled"
        />
      </template>
    </form-group>
    <form-group
      :validator="v$.value[jobAttribute]"
      label="Job"
      class="job"
      v-bind="formGroupConfig"
    >
      <template #default="slotProps">
        <remote-multiselect
          v-bind="slotProps"
          ref="jobRef"
          v-model="value[jobAttribute]"
          :id="uniqueIdentifier"
          label="name"
          track-by="id"
          select-label=""
          deselect-label=""
          :serviceFetch="fetchJobs"
          :disabled="disabled"
          @fullValueChange="state.job = $event"
        />
      </template>
    </form-group>
    <form-group
      :validator="v$.value[jobPhaseAttribute]"
      label="Job phase"
      class="job-phase"
      v-bind="formGroupConfig"
      v-if="jobPhaseEnabled"
    >
      <template #default="slotProps">
        <remote-multiselect
          v-bind="slotProps"
          ref="jobPhaseRef"
          v-model="value[jobPhaseAttribute]"
          :id="uniqueIdentifier"
          label="name"
          track-by="id"
          select-label=""
          deselect-label=""
          :serviceFetch="fetchJobPhases"
          :disabled="disabled"
          @fullValueChange="state.jobPhase = $event"
        />
      </template>
    </form-group>
    <form-group
      :validator="v$.value[costCodeAttribute]"
      label="Cost code"
      class="cost-code"
      v-bind="formGroupConfig"
      v-if="costCodeEnabled"
    >
      <template #default="slotProps">
        <remote-multiselect
          v-bind="slotProps"
          ref="costCodeRef"
          v-model="value[costCodeAttribute]"
          :id="uniqueIdentifier"
          label="name"
          track-by="id"
          select-label=""
          deselect-label=""
          :disabled="disabled"
          :serviceFetch="fetchCostCodes"
          @fullValueChange="state.costCode = $event"
        />
      </template>
    </form-group>
    <form-group v-if="enablePartialCosting">
      <b-form-checkbox v-model="value[costingIsPartialAttribute]" :disabled="disabled">
        Costing Selection is Partial
        <help-text-icon>
          If checked, then the worker will be prompted on the time clock to select
          any fields which were not locked here, as long as that field has values to select.
          <template v-if="showMinVersion">
            <br><br>
            This feature requires time clock mobile app version 2.11 or later for job phase and cost code partial lock.
            The job label lock requires version 3.1 or later.
          </template>
        </help-text-icon>
      </b-form-checkbox>
    </form-group>
  </div>
</template>

<script setup>
import { computed, getCurrentInstance, reactive, ref, watch } from 'vue'
import { useStore } from 'vuex'
import JobService from '../costing/services/JobService'
import JobPhaseService from '../costing/services/JobPhaseService'
import CostCodeService from '../costing/services/CostCodeService'
import LabelSelect from '@/views/settings/organization/LabelSelect.vue'
import RemoteMultiselect from '@/components/RemoteMultiselect.vue'
import HelpTextIcon from '@/components/HelpTextIcon.vue'
import { forceArray } from '@/utils/misc'
import { useVuelidate } from '@vuelidate/core'

// TODO: Also temporarily disable requirePerms and work filtering for certain particular orgs until we resolve query branch explosion.
// TODO: Probably need to wait until we upgrade our backend to using native IN queries.
const DISABLE_PERMS_ORG_IDS = [5544977541234688, 5828268817121280]

const props = defineProps({
  value: Object,
  // For bulk actions, we are querying for common values.
  worker: [Number, Array],
  deviceOrgUnit: [Number, Array],
  orgUnit: [Number, Array],
  department: [Number, Array],
  userLabels: Array,
  customFieldValues: Array,
  jobLabelAttribute: {
    type: String,
    default: 'jobLabel'
  },
  jobAttribute: {
    type: String,
    default: 'job'
  },
  jobPhaseAttribute: {
    type: String,
    default: 'jobPhase'
  },
  costCodeAttribute: {
    type: String,
    default: 'costCode'
  },
  costingIsPartialAttribute: {
    type: String,
    default: 'costingIsPartial'
  },
  enablePartialCosting: {
    type: Boolean,
    default: true
  },
  enableJobRequiredValidation: {
    type: Boolean,
    default: false
  },
  formGroupConfig: Object,
  invalidMessage: {
    type: String,
    default: 'This selection is not valid.'
  },
  disabled: {
    type: Boolean,
    default: false
  },
  showMinVersion: {
    type: Boolean,
    default: true
  },
  disableRequirePerms: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(['jobChanged', 'validityChanged'])
const state = reactive({
  // These values each hold entire option object,
  // for filtering and validation.
  job: null,
  jobPhase: null,
  costCode: null
})
const jobRef = ref(null)
const jobPhaseRef = ref(null)
const costCodeRef = ref(null)
const store = useStore()
const partialCostingChecked = computed(() => props.enablePartialCosting && props.value[props.costingIsPartialAttribute])
const jobLabelEnabled = computed(() => partialCostingChecked.value && !state.job)
const jobPhaseEnabled = computed(() => store.getters.enhancedCostingEnabled && (state.job ? !!state.job.jobPhaseMode : partialCostingChecked.value))
const costCodeEnabled = computed(() => store.getters.enhancedCostingEnabled && (state.job ? state.job.costCodeMode : partialCostingChecked.value))
// TODO: Do job phase and cost code fields need a different identifier that includes job id?
const uniqueIdentifier = computed(() => `${props.value.id}-${props.value.employee}-${forceArray(props.deviceOrgUnit || []).join('-')}`)
const customFieldValueParams = computed(() =>
  (props.customFieldValues || [])
    .filter(value => ['bool', 'choice'].includes(value.type))
    .flatMap(value => forceArray(value.value).map(v => `${value.field}:${v}`))
)

const v$ = useVuelidate(
  {
    // TODO: Try to do some validation when costing is partial.
    value: {
      [props.jobLabelAttribute]: {
        // It doesn't seem we need any validation for label.
      },
      [props.jobAttribute]: {
        isValid: {
          $message: props.invalidMessage,
          $validator: value => {
            if (props.enablePartialCosting && props.value[props.costingIsPartialAttribute]) return true
            if (value) return true
            if (props.enableJobRequiredValidation && store.state.jobRequired) return false
            return true
          }
        }
      },
      [props.jobPhaseAttribute]: {
        isValid: {
          $message: props.invalidMessage,
          $validator: value => {
            if (!store.getters.enhancedCostingEnabled) return true
            if (props.enablePartialCosting && props.value[props.costingIsPartialAttribute]) return true
            if (!state.job) return !value
            if (!value && state.job.jobPhaseMode === 'require') return false
            if (!state.job.jobPhaseMode) return !value
            if (!state.jobPhase) return true // I think this case is transient during loading
            if (value && state.jobPhase.job && state.jobPhase.job !== props.value[props.jobAttribute]) return false
            return true
          }
        }
      },
      [props.costCodeAttribute]: {
        isValid: {
          $message: props.invalidMessage,
          $validator: value => {
            if (!store.getters.enhancedCostingEnabled) return true
            if (props.enablePartialCosting && props.value[props.costingIsPartialAttribute]) return true
            if (!state.job) return !value
            if (!value && state.job.costCodeMode === 'require') return false
            if (!state.job.costCodeMode) return !value
            if (!state.costCode) return true // I think this case is transient during loading
            if (value && state.costCode.job && state.costCode.job !== props.value[props.jobAttribute]) return false
            return true
          }
        }
      }
    }
  },
  // TODO: Is there a smaller state than entire instance proxy?
  getCurrentInstance().proxy,
  { $scope: false, $stopPropagation: true }
)

function fetchJobs (searchText, value, limit) {
  return JobService
    .list({
      searchText,
      active: value ? 'all' : 'active',
      id: value,
      limit,
      requirePerms: !props.disableRequirePerms,
      worker: props.worker,
      deviceOrgUnit: props.deviceOrgUnit,
      orgUnit: props.orgUnit,
      department: props.department,
      userLabel: props.userLabels,
      customFieldValues: customFieldValueParams.value
    })
    .then(data => data.results ?? [])
}

function fetchJobPhases (searchText, value, limit) {
  const job = props.value[props.jobAttribute]
  return JobPhaseService
    .list({
      searchText,
      active: value ? 'all' : 'active',
      id: value,
      limit,
      worker: props.worker,
      deviceOrgUnit: props.deviceOrgUnit,
      orgUnit: props.orgUnit,
      department: props.department,
      userLabel: props.userLabels,
      customFieldValues: customFieldValueParams.value,
      // Don't require perms if partial costing without a job, or for certain orgs as explained above.
      ...(props.enablePartialCosting && !job || DISABLE_PERMS_ORG_IDS.includes(store.state.organizationId) ? {} : {
        job,
        jobPhase: props.value[props.jobPhaseAttribute],
        requirePerms: !props.disableRequirePerms,
        workLabel: state.job?.labels
      })
    })
    .then(data => data.results ?? [])
}

function fetchCostCodes (searchText, value, limit) {
  const job = props.value[props.jobAttribute]
  return CostCodeService
    .list({
      searchText,
      active: value ? 'all' : 'active',
      id: value,
      limit,
      worker: props.worker,
      deviceOrgUnit: props.deviceOrgUnit,
      orgUnit: props.orgUnit,
      department: props.department,
      userLabel: props.userLabels,
      customFieldValues: customFieldValueParams.value,
      // Don't require perms if partial costing without a job, or for certain orgs as explained above.
      ...(props.enablePartialCosting && !job || DISABLE_PERMS_ORG_IDS.includes(store.state.organizationId) ? {} : {
        job,
        jobPhase: props.value[props.jobPhaseAttribute],
        requirePerms: !props.disableRequirePerms,
        workLabel: (state.job?.labels || []).concat(state.jobPhase?.labels || [])
      })
    })
    .then(data => data.results ?? [])
}

watch(
  () => state.job,
  job => {
    // TODO: If job changes, it needs to trigger fetchJobPhases and fetchCostCodes due to permissions effects.
    emit('jobChanged', job)
    if (!store.getters.enhancedCostingEnabled) return
    if (job) {
      // TODO: If jobPhaseMode off, shouldn't we also remove all job phase options?
      // TODO: And if jobPhaseMode off, we might need to clear job phases anyway
      // TODO: because those are the job phases for the previous job.
      // TODO: And when parent id changes we may need to also clear everything,
      // TODO: because org unit / department may have changed.
      // TODO: Similarly with cost codes.
      if (!job.jobPhaseMode) props.value[props.jobPhaseAttribute] = null
      if (!job.costCodeMode) props.value[props.costCodeAttribute] = null
      // Use condition to avoid setting job label null and making form unnecessarily dirty.
      if (jobLabelEnabled.value && props.value[props.jobLabelAttribute]) {
        props.value[props.jobLabelAttribute] = null
      }
    } else {
      props.value[props.jobPhaseAttribute] = null
      props.value[props.costCodeAttribute] = null
    }
  }
)

watch(
  () => props.department,
  () => {
    // refetch jobs since filter changed
    jobRef.value?.trySearch?.()
  }
)

watch(
  () => props.deviceOrgUnit,
  () => {
    // refetch jobs since filter changed
    jobRef.value?.trySearch?.()
  }
)

watch(
  partialCostingChecked,
  () => {
    if (!jobPhaseEnabled.value) {
      props.value[props.jobPhaseAttribute] = null
    }
    if (!costCodeEnabled.value) {
      props.value[props.costCodeAttribute] = null
    }
  }
)

watch(
  jobLabelEnabled,
  v => {
    if (!v) {
      props.value[props.jobLabelAttribute] = null
    }
  }
)

watch(
  () => v$.value.$invalid,
  v => {
    emit('validityChanged', !v)
  },
  { immediate: true }
)

</script>

<style lang="scss" scoped>
.costing-selection {
  .form-group {
    margin: .5rem;
  }
  .multiselect {
    max-width: 20rem;
  }
}
</style>
