import restClient from '@/services/clients/rest'
import { createRestCrudService } from '@/services/GenericCrudService'

const resourceName = 'Job'
const resourcePath = 'costing/jobs'
const crudService = createRestCrudService(resourceName, resourcePath)

export default Object.assign(crudService, {
  async bulkImport (items, options) {
    try {
      const response = await restClient.post(`${resourcePath}/bulk-import`, {
        items,
        options
      })
      return response.data
    } catch (error) {
      console.warn(`Error submitting job bulk import`, error, items, options)
      throw error
    }
  },
  async applyBulkAction (actionData, itemIds) {
    try {
      const response = await restClient.post(`${resourcePath}/bulk-action`, {
        itemIds,
        actionData
      })
      return response.data
    } catch (error) {
      console.warn(`Error submitting bulk action`, error, actionData)
      throw error
    }
  }
})
