<template>
  <key-multiselect
    ref='select'
    v-bind="$attrs"
    :modelValue="modelValue"
    :options="sortedOptions"
    :loading="loading"
    :clearOnSelect="false"
    :preserveSearch="true"
    :internalSearch="internalSearch"
    :class="['remote-multiselect', {selected: !!modelValue}]"
    @update:modelValue="onChange"
    @open="onOpen"
    @search-change="onSearchChange"
    @select="onSelect"
  >
    <template #beforeList>
      <slot name="beforeList1" />
      <li class="limit-text" v-show="options.length >= limit">
        * More than {{ limit }} results found. Type some letters to narrow search criteria.
      </li>
      <slot name="beforeList2" />
    </template>

    <template v-for="(_, name) in passThruSlots" :key="name" #[name]="slotData">
      <slot :name="name" v-bind="slotData" />
    </template>
  </key-multiselect>
</template>
<script>
import KeyMultiselect from './KeyMultiselect.vue'
import _ from 'lodash'
import { hasValue, forceArray } from '@/utils/misc'

export default {
  name: 'RemoteMultiselect',
  inheritAttrs: false,
  components: {
    KeyMultiselect
  },
  data () {
    return {
      // listLoaded indicates that we requested options from the server,
      // aside from the selected values. It hints to whether we should
      // make a load request when opening the widget.
      listLoaded: false,
      loading: false,
      options: [],
      // Store last search text so that we can refresh under certain circumstances.
      lastSearchText: null
    }
  },
  props: {
    id: {
      type: [Number, String]
    },
    modelValue: [Number, String, Array],
    limit: {
      type: Number,
      default: 25
    },
    serviceFetch: {
      // arguments: (searchText: string, value: number | string | array, limit: number)
      type: Function,
      required: true
    },
    internalSearch: {
      type: Boolean,
      default: false
    }
    // clearOnSelect: {
    //   type: Boolean,
    //   default: true
    // },
    // preserveSearch: {
    //   type: Boolean,
    //   default: false
    // }
  },
  // TODO: fullValueChange, add-new-item?
  emits: ['update:modelValue', 'options-changed'],
  computed: {
    sortedOptions () {
      return _.sortBy(this.options, item => item[this.$attrs.label])
    },
    passThruSlots () {
      return Object.fromEntries(Object.entries(this.$slots)
        .filter(([k, v]) => !['beforeList', 'beforeList1', 'beforeList2'].includes(k))
      )
    }
  },
  watch: {
    // If the newly-set value is not already loaded in the local list of options,
    // then we will remotely request it.
    modelValue: {
      handler (newValue, oldValue) {
        const values = forceArray(newValue)
        const valuesNotLoaded = values.filter(v => !this.findOption(v, this.options))

        // TODO: There is an edge case bug where an inactive option was loaded
        // TODO: for an existing value. Then, when the inactive value is removed,
        // TODO: either by user or by changing parent context, then inactve option
        // TODO: still remains in list. We should remove such inactive options once
        // TODO: they're no longer in the value set. This can be easlily tested with
        // TODO: labels.

        if (_.isEmpty(valuesNotLoaded)) {
          // no value set, or all values loaded
          return
        }

        // invalidate any pending request
        this.loading = false

        // Before we pass the value onto the child component, we need to download it
        // from server.
        this.search(null, valuesNotLoaded)
          .catch(() => {})
      },
      immediate: true
    },
    id () {
      // Clear search text on VueMultiselect component
      // when parent id (e.g., model id) changes. We
      // need to do this, because we hard code preserveSearch
      // prop to true.
      const select = this.$refs.select?.$refs?.select
      if (select) select.search = ''
    },
    options (options) {
      this.$emit('options-changed', options)
    }
  },
  methods: {
    onChange (value) {
      // prevent infinite feedback loop
      if (!_.isEqual(value, this.modelValue)) {
        this.$emit('update:modelValue', value)
      }
    },

    onOpen () {
      // Be smart about only loading if necessary
      if (!this.listLoaded) {
        this.trySearch()
      }
    },

    onSelect () {
      // When the clearOnSelect prop is true, then selecting an option will
      // automatically clear the search field. We need to make sure in such
      // a case that we don't invoke a remote search. So the following boolean
      // flag prevents that from happening.
      this.ignoreNextSearchChange = true
    },

    onSearchChange (searchText) {
      // if (this.ignoreNextSearchChange) {
      //   this.ignoreNextSearchChange = false
      //   return
      // }
      if (this.internalSearch) return
      this.lastSearchText = searchText
      this.debouncedSearch(searchText)
    },

    trySearch (searchText) {
      this.search(searchText).catch(error => {
        console.warn('Search failed', error)
      })
    },

    search (searchText, value) {
      if (this.loading) return Promise.reject(new Error('Already loading'))

      this.loading = true

      // We'll check if current value changed when result is received, in order to drop it.
      // We use this.modelValue instead of value, because we might not be searching the current value.
      const currentValue = this.modelValue

      return this.serviceFetch(searchText, value, this.limit)
        .then(options => {
          // TODO: If the following condition doesn't properly eliminate requests when the input
          // TODO: changes, then instead consider adding a prop for a model id.
          if (!_.isEqual(currentValue, this.modelValue) && (hasValue(currentValue) || hasValue(this.modelValue))) {
            return Promise.reject(
              new Error(`Value changed from "${currentValue}" to "${this.modelValue}", so this request no longer valid`))
          }

          const values = forceArray(this.modelValue)

          this.options = _.chain(this.options)
            // filter out old options which are not in value set
            .filter(option => values.includes(this.optionValue(option)))
            // add new options
            .concat(options)
            // dedupe
            .uniqBy(option => option.id)
            .value()

          // If any values not found in options after request, then remove them.
          const valuesInOptions = values.filter(v => this.findOption(v, this.options))
          if (!_.isEqual(valuesInOptions, values)) {
            // console.log(`RemoteMultiSelect: values ${_.difference(values, ...valuesInOptions)} removed from list of options`)
            if (this.$attrs['multiple']) {
              this.onChange(valuesInOptions)
            } else {
              this.onChange(valuesInOptions.length > 0 ? valuesInOptions[0] : null)
            }
          }

          this.listLoaded = this.listLoaded || !hasValue(value)

          if (searchText != this.lastSearchText) {
            console.log('Search text input was changed while loading. Run new fetch.')
            this.loading = false
            setTimeout(() => this.search(this.lastSearchText, value).catch(() => {}))
          }
        })
        .catch(error => {
          // TODO
          console.warn('failed to load selections', error)
        })
        .finally(() => {
          this.loading = false
        })
    },

    findOption (value, options) {
      return value ? options.find(option => this.optionValue(option) === value) : undefined
    },

    optionValue (option) {
      return option[this.$attrs['track-by']]
    },

    clear () {
      this.options = []
      this.listLoaded = false
      this.onChange(null)
    },

    // refresh may be called by parent via ref.
    refresh () {
      this.trySearch(this.lastSearchText)
    }
  },
  created () {
    // Debounce construction needs to be isolated per instance.
    this.debouncedSearch = _.debounce(function (searchText) {
      this.trySearch(searchText)
    }, 500)
  }
}
</script>
<style lang="scss">
@use "sass:map";
@import '@/assets/scss/_bootstrap-variables';

$v-select-width: 15rem;

.remote-multiselect {

  // TODO: Originally we set width (not max-width) to deal with select box
  // TODO: being really wide on wide screens. But it's still too wide on
  // TODO: narrow devices (phone), so we'll change it to max-width. I'm
  // TODO: still not really sure if this is the right approach.
  max-width: $v-select-width;

  .multiselect__content {

    max-width: 100%;

    .active > a {
      background-color: map.get($theme-colors, primary);
      color: #fff !important;
    }

    // hover background
    > .highlight > a {
      background-color: map.get($theme-colors, primary);
      color: #fff !important;
    }

    li {
      line-height: 2.5;

      > a {
        text-overflow: ellipsis;
        overflow-x: hidden;
      }
    }

    .limit-text {
      background-color: #F39C12;
      color: #fff;
      font-size: 12px;
      line-height: 1.2;
      padding: 10px;
      font-weight: bold;
    }
  }

  &.selected {
    input[type=search] {
      // override the strange styling of this element to width:auto when
      // value is selected. it causes a line break in the select box for
      // long values. we must use width:0 instead of hiding the element,
      // because it must be visible in order for the blur event to trigger.
      width: 0 !important;
    }

    &.open {
      input[type=search] {
        display: inline-block;
      }
    }
  }

  .dropdown-toggle {
    display: block;
    overflow: hidden;
    white-space: nowrap;
  }

  .selected-tag {
    margin-bottom: 4px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: calc(100% - 43px); // subtract width of clear and dropdown icons
  }
}
</style>
