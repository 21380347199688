<template>
  <footer class="app-footer">
    <span class="copyright">&copy; Fareclock</span>
    <span class="terms"><a href="https://www.fareclock.com/terms">Terms of Use</a></span>
    <span class="privacy"><a href="https://www.fareclock.com/privacy">Privacy Policy</a></span>
  </footer>
</template>
<script>
export default {
  name: 'c-footer',
  data () {
    return {
    }
  }
}
</script>
<style lang="scss" scoped>

  .app-footer {
    .copyright, .terms {
      margin-right: 50px;
    }

    @media screen and (max-width: 425px) {
      .copyright {
        display: none;
      }
    }
  }

</style>
