<template>
  <button class="sidebar-minimizer" type="button" @click="minimize()"></button>
</template>
<script>
import storage from '@/utils/SafeLocalStorage'

export default {
  name: 'sidebar-minimizer',
  data () {
    return {
      sidebarMinimized: false
    }
  },
  methods: {
    sidebarMinimize () {
      document.body.classList.toggle('sidebar-minimized')
    },
    brandMinimize () {
      document.body.classList.toggle('brand-minimized')
    },
    minimize () {
      this.sidebarMinimize()
      this.brandMinimize()

      if (this.sidebarMinimized) {
        storage.removeItem('sidebarMinimized')
        this.sidebarMinimized = false
      } else {
        storage.setItem('sidebarMinimized', 'true')
        this.sidebarMinimized = true
      }
    }
  },
  created () {
    if (storage.getItem('sidebarMinimized')) {
      this.minimize()
    }
  }
}
</script>
