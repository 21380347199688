import axios from 'axios'
import constants from '@/constants'

const instance = axios.create({
  baseURL: constants().loginServer
})

// because setting withCredentials in create config doesn't work.
// neither does using instance.defaults.
instance.interceptors.request.use(function (config) {
  config.withCredentials = true

  return config
}, function (error) {
  return Promise.reject(error)
})

export default instance
