import restClient from './clients/rest'

const service = {
  async retrieveSettings (orgId) {
    try {
      const response = await restClient.get(`session`, { orgId })
      return response.data
    } catch (error) {
      console.warn('Error retrieving settings', error)
      throw error
    }
  }
}

export default service
