<template>
  <div class="app">
    <AppHeader />
    <div class="app-body">
      <NavSidebar :navItems="nav"/>
      <main class="main">
        <SubscriptionAlertBar/>
        <div class="container-fluid main-pane">
          <router-view v-slot="{ Component }">
            <keep-alive :include="includeInKeepAlive">
              <component :is="Component" />
            </keep-alive>
          </router-view>
        </div>
      </main>
    </div>
    <AppFooter/>
  </div>
</template>

<script>
import { useRouterViewMeta } from '@/composables/useRouterViewMeta'
import nav from '../OrgNav'
import { Header as AppHeader, NavSidebar, Footer as AppFooter } from '.'
import SubscriptionAlertBar from './SubscriptionAlertBar.vue'

export default {
  name: 'Full',
  setup () {
    const routerViewMeta = useRouterViewMeta()
    return {
      ...routerViewMeta
    }
  },
  components: {
    AppHeader,
    AppFooter,
    NavSidebar,
    SubscriptionAlertBar
  },
  data () {
    return {
      nav: nav.navItems
    }
  }
}
</script>
<style lang="scss" scoped>
@use '@/assets/scss/variables';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

$base-margin-top: 1.5rem;

.main-pane {
  margin-top: $base-margin-top;
}
.alert-bar-visible {
  .main-pane {
    margin-top: calc(#{$base-margin-top} + #{variables.$alert-bar-height});

    @include media-breakpoint-down(md) {
      margin-top: calc(#{$base-margin-top} + #{variables.$alert-bar-sm-height});
    }

    @include media-breakpoint-only(sm) {
      margin-top: calc(#{$base-margin-top} + #{variables.$alert-bar-xs-height});
    }
  }
}
</style>
