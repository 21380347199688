<template>
  <div class="overlay-loading ag-overlay-loading-center">
    <template v-if="params.errorMessage">
      <div class="error-message">
        <font-awesome-icon icon="triangle-exclamation" />
        {{ params.errorMessage }}
      </div>
      <b-btn variant="outline-primary" @click="params.retry()">
        <font-awesome-icon icon="cloud-download-alt" />
        Try again
      </b-btn>
    </template>
    <template v-else>
      <font-awesome-icon icon="circle-notch" spin />
      Loading...
    </template>
  </div>
</template>

<script setup></script>

<style lang="scss">
@use '@/assets/scss/variables';

.overlay-loading {
  .error-message {
    color: variables.$flat-ui-alizarin;
  }
  .btn {
    margin-top: 15px;
  }
  svg {
    margin-right: .25rem;
  }
}
</style>
