import { createCrudService } from '@/services/GenericCrudService'
import notificationClient from '@/services/clients/notification'

const resourceName = 'Notification Message'

export default function crudServiceFactory (orgId, orgUserId, userId) {
  const resourcePath = orgId && orgUserId ? `orgs/${orgId}/users/${orgUserId}/messages` : `users/${userId}/messages`
  const crudService = createCrudService(notificationClient, resourceName, resourcePath)
  return Object.assign(crudService, {
    async setMessageUnread (itemId, unread) {
      try {
        await notificationClient.post(`${resourcePath}/${itemId}/${unread ? 'unread' : 'read'}`)
      } catch (error) {
        console.warn(`Error setting ${resourceName} ${itemId} unread ${unread}`, error)
        throw error
      }
    },
    async deleteMessage (itemId) {
      try {
        await notificationClient.delete(`${resourcePath}/${itemId}`)
      } catch (error) {
        console.warn(`Error deleting ${resourceName} ${itemId}`, error)
        throw error
      }
    }
  })
}
