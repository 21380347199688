import restClient from '@/services/clients/rest'
import { createRestCrudService } from '@/services/GenericCrudService'
import _ from 'lodash'
import { hasValue } from '@/utils/misc'

const resourceName = 'Label'
const resourcePath = 'labels'
const crudService = createRestCrudService(resourceName, resourcePath)

export default Object.assign(crudService, {
  // searchInactive is typically set to true on filter fields,
  // but not for form fields.
  fetchForSelect (labelType, searchInactive) {
    return (searchText, value, limit) => {
      const valueIsSet = hasValue(value)

      // Value can have a variety of forms. First it can be a single value,
      // or it can be an array of values. Then, each value can be either a
      // simple id, or it can be of format {type}:{id}.
      // The latter format can happen when using a RemoteMultiSelect with
      // track-by targetId. In such a case, we should strip off type, and just
      // make request with id.
      const transformValue = v => _.isString(v) && v.includes(':') ? v.split(':')[1] : v
      const id = _.isArray(value) ? value.map(transformValue) : transformValue(value)

      return crudService
        .list({
          searchText,
          active: valueIsSet || (searchInactive && searchText) ? 'all' : 'active',
          // TODO: Should searchInactive apply to type?
          type: valueIsSet ? undefined : labelType,
          id,
          limit
        })
        .then(data => _.get(data, 'results', []))
        .then(items => {
          items.forEach(item => item.targetId = `${item.type}:${item.id}`)
          return items
        })
    }
  },
  async bulkImport (items, options) {
    try {
      const response = await restClient.post(`${resourcePath}/bulk-import`, {
        items,
        options
      })
      return response.data
    } catch (error) {
      console.warn(`Error submitting label bulk import`, error, items, options)
      throw error
    }
  }
})
