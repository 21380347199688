
// silently handle errors such "Access is denied" in IE
// TODO: add other methods, properties, events, etc.
// TODO: We don't support IE anymore, so we can remove this abstraction layer.
export default {
  setItem (keyName, keyValue) {
    try {
      return window.localStorage.setItem(keyName, keyValue)
    } catch (error) { /**/ }
  },
  getItem (keyName) {
    try {
      return window.localStorage.getItem(keyName)
    } catch (error) { /**/ }
  },
  removeItem (keyName) {
    try {
      return window.localStorage.removeItem(keyName)
    } catch (error) { /**/ }
  }
}
