<template>
  <div>
    <b-button id="notification-bell-button" pill variant="link" class="bell-button">
      <font-awesome-layers full-width class="fa">
        <font-awesome-icon icon="bell"  />
        <font-awesome-layers-text v-if="summaryCountDisplay" counter :value="summaryCountDisplay" position="top-right" />
      </font-awesome-layers>
    </b-button>
    <b-popover
      target="notification-bell-button"
      triggers="click"
      placement="bottom"
      custom-class="notification-popover"
      @show="popoverOpen = true"
      @hidden="popoverOpen = false"
    >
      <template #title>Notifications</template>
      <div v-if="userNotificationsEnabled" class="notification-message-list">
        <div v-if="notificationMessageItemsLoading" key="loading">
          <spinner />
        </div>
        <div v-else-if="notificationMessageItemsLoadLastError" key="error" class="error-message">
          <font-awesome-icon icon="triangle-exclamation" class="icon" />
          <span>{{  notificationMessageItemsLoadLastError }}</span>
        </div>
        <div v-else key="items" class="item-list">
          <div v-for="item in notificationMessageItems" :key="item.id" :class="['item', item.unread ? 'unread' : 'read']">
            <div class="header">
              <font-awesome-icon :icon="item._deleting ? 'circle-notch' : 'xmark'" :spin="!!item._deleting" class="delete" @click="deleteNotificationMessage(item)" />
              <font-awesome-icon :icon="item._togglingUnread ? 'circle-notch' : (item.unread ? 'envelope' : 'envelope-open')" :spin="!!item._togglingUnread" class="toggle-unread" @click="toggleNotificationMessageUnread(item)" />
            </div>
            <div class="content">
              <div class="message">
                <div class="title">
                  {{ item.title }}
                </div>
                <div class="body">
                  {{ item.body }}
                </div>
                <div class="org" v-if="item.orgId !== organizationId">
                  {{ item.orgName }}
                </div>
              </div>
              <div class="timestamp">
                <div class="date">
                  {{ formatDate(item.timestamp) }}
                </div>
                <div class="time">
                  {{ formatTime(item.timestamp) }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer">
          <b-link :to="notificationMessagesLink">
            See all notification messages
          </b-link>
          <b-form-checkbox :checked="showAllOrgs" switch @change="setShowAllOrgs($event)" :disabled="notificationMessageItemsLoading">
            Show all organizations
          </b-form-checkbox>
        </div>
        <hr>
      </div>
      <div v-if="summaryCountDisplay" class="notification-list">

        <div v-if="notificationMessageCountDisplay" class="notification-item">
          <font-awesome-icon icon="circle-exclamation" />
          There {{ notificationMessageCount > 1 ? 'are' : 'is' }} {{ notificationMessageCountDisplay }} unread Notification {{ inflect('message', notificationMessageCount) }}.
          <br>
          <b-link :to="notificationMessagesLink">View notifications</b-link>
        </div>

        <div v-if="disciplineInfractionCountDisplay" class="notification-item">
          <font-awesome-icon icon="circle-exclamation" />
          There {{ disciplineInfractionCount > 1 ? 'are' : 'is' }} {{ disciplineInfractionCountDisplay }} open Attendance {{ inflect('Infraction', disciplineInfractionCount) }}.
          <br>
          <b-link :to="{name: 'discipline-infractions'}">View infractions</b-link>
        </div>

        <div v-if="disciplineEventCountDisplay" class="notification-item">
          <font-awesome-icon icon="circle-exclamation" />
          There {{ disciplineEventCount > 1 ? 'are' : 'is' }} {{ disciplineEventCountDisplay }} open Attendance {{ inflect('Event', disciplineEventCount) }}.
          <br>
          <b-link :to="{name: 'discipline-events'}">View events</b-link>
        </div>

        <div v-if="missingPunchRequestCountDisplay" class="notification-item">
          <font-awesome-icon icon="circle-exclamation" />
          There {{ missingPunchRequestCount > 1 ? 'are' : 'is' }} {{ missingPunchRequestCountDisplay }} pending Missed Punch {{ inflect('request', missingPunchRequestCount) }}.
          <br>
          <b-link :to="{name: 'missed-punch-list'}">View requests</b-link>
        </div>

        <div v-if="timeOffRequestCountDisplay" class="notification-item">
          <font-awesome-icon icon="circle-exclamation" />
          There {{ timeOffRequestCount > 1 ? 'are' : 'is' }} {{ timeOffRequestCountDisplay }} pending Time Off {{ inflect('request', timeOffRequestCount) }}.
          <br>
          <b-link :to="{name: 'timeoff-requests'}">View requests</b-link>
        </div>
      </div>
      <div v-else>
        You do not currently have any notifications.
      </div>
    </b-popover>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import { inflect } from 'inflection'
import Spinner from '@/components/Spinner.vue'
import moment from 'moment-timezone'
import { extractErrorMessage } from '@/utils/misc'

export default {
  name: 'NotificationBell',
  components: {
    Spinner
  },
  data () {
    return {
      inflect,
      popoverOpen: false
    }
  },
  computed: {
    ...mapState(['organizationId']),
    ...mapGetters(['userNotificationsEnabled']),
    ...mapGetters('dashboardNotifications', [
      'summaryCountDisplay',
      'timeOffRequestCount',
      'timeOffRequestCountDisplay',
      'missingPunchRequestCount',
      'missingPunchRequestCountDisplay',
      'disciplineInfractionCountDisplay',
      'disciplineEventCountDisplay',
      'disciplineInfractionCount',
      'disciplineEventCount',
      'notificationMessageCount',
      'notificationMessageCountDisplay',
      'notificationMessageItemsLoading'
    ]),
    ...mapState('dashboardNotifications', [
      'notificationMessageItems',
      'notificationMessageItemsLoadLastError',
      'showAllOrgs'
    ]),
    ...mapGetters('formatPreferences', ['formatShortDate', 'formatTime']),
    notificationMessagesLink () {
      return this.$store.getters.insideOrgNamespace
        ? { name: 'org-notification-messages', params: { orgId: this.$store.state.organizationId } }
        : '/notifications'
    }
  },
  watch: {
    popoverOpen (popoverOpen) {
      // console.log(`popoverOpen = ${popoverOpen}`)
      if (popoverOpen && this.userNotificationsEnabled) {
        this.$store.dispatch('dashboardNotifications/loadNotificationMessageItems')
      }
    }
  },
  methods: {
    formatDate (timestamp) {
      const m = moment(timestamp)
      if (m.isAfter(moment().startOf('day'))) {
        return 'Today'
      } else if (m.isAfter(moment().startOf('year'))) {
        return m.format('MMM D')
      } else {
        return this.formatShortDate(m)
      }
    },
    async deleteNotificationMessage (item) {
      if (item._deleting || item._togglingUnread) return
      item._deleting = true
      try {
        await this.$store.dispatch('dashboardNotifications/deleteNotificationMessage', item.id)
        const masterItems = this.$store.state.notificationMessageMasterDetail.masterItems.filter(i => i.id !== item.id)
        this.$store.commit('notificationMessageMasterDetail/masterItemsChanged', {
          masterItems: [...masterItems],
          cursor: this.$store.state.notificationMessageMasterDetail.cursor
        })
      } catch (error) {
        this.$toast.error(extractErrorMessage(error))
      } finally {
        item._deleting = false
      }
    },
    async toggleNotificationMessageUnread (item) {
      if (item._togglingUnread || item._deleting) return
      item._togglingUnread = true
      try {
        const unread = !item.unread
        await this.$store.dispatch('dashboardNotifications/setNotificationMessageUnread', { itemId: item.id, unread })
        const { masterItems, cursor } = this.$store.state.notificationMessageMasterDetail
        const masterItem = masterItems.find(i => i.id === item.id)
        if (masterItem) {
          masterItem.unread = unread
          this.$store.commit('notificationMessageMasterDetail/masterItemsChanged', {
            masterItems: [...masterItems],
            cursor
          })
        }
      } catch (error) {
        this.$toast.error(extractErrorMessage(error))
      } finally {
        item._togglingUnread = false
      }
    },
    setShowAllOrgs (showAllOrgs) {
      this.$store.dispatch('dashboardNotifications/setShowAllOrgs', showAllOrgs)
    }
  },
}
</script>
<style lang="scss" scoped>
@use '@/assets/scss/variables';

.bell-button {
  color: #536c79;
  font-size: 22px;
  padding: 0.25rem 0.5rem;

  &:focus, &:hover {
    box-shadow: none;
    border-radius: 50%;
    color: #29363d;
  }

  &:hover {
    background-color: #eee;
  }

  &:focus {
    background-color: #ddd;
  }

  .fa-layers {
    height: 30px;
    width: 30px;
    margin-top: 5px;

    .fa-bell {
    }

    .fa-layers-counter {
      transform: scale(0.5);
    }
  }
}

.notification-popover {
  // Override bootstrap hard-code 276px max width.
  // https://bootstrap-vue.org/docs/components/popover#advanced-b-popover-usage-with-reactive-content
  max-width: 400px;
}

.notification-message-list {
  .item-list {
    .item {
      border-bottom: 1px solid #aaa;
      padding: 5px 0;
      display: flex;
      flex-direction: column;

      &.unread {
        font-weight: bold;
      }

      .header {
        margin-bottom: .25rem;

        svg {
          float: right;
          color: #666;
          cursor: pointer;
        }

        .toggle-unread {
          margin-right: .5rem;
        }
        .delete {
        }
      }

      .content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .message {
          .body {
            color: #888;
          }
          .org {
            color: variables.$flat-ui-carrot;
          }
        }
        .timestamp {
          text-align: end;
          color: #888;
          margin-right: 1rem;
        }
      }
    }
  }

  .footer {
    margin-top: .5rem;
  }
  hr {
    margin-top: .5rem;
  }
}

.notification-list {
  margin-bottom: 10px;
  .notification-item {
    margin-top: .5rem;
    svg {
      margin-right: 5px;
    }
    a {
      margin-left: 20px;
    }
  }
}
</style>
