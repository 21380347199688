<template>
  <li class="panel-header-icon" @click="click"><font-awesome-icon :icon="icon" /></li>
</template>
<script>
export default {
  props: {
    icon: String
  },
  methods: {
    click () {
      this.$emit('click')
    }
  }
}
</script>
<style lang="scss" scoped>
  .panel-header-icon {
    font-size: 1rem;
    cursor: pointer;
  }
</style>
