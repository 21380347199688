import moment from 'moment-timezone'
import { modelDateFormat, modelTimeFormat, parseTimeFormat } from '@/utils/misc'
import duration from '@/utils/duration'
import { repeat, round } from 'lodash'
import { registerSingletonResourceWithStore } from '@/services/GenericSingletonResourceService'
import { inflect } from 'inflection'

const moduleName = 'formatPreferences'

export function registerFormatPreferencesWithStore (store) {
  if (store.state[moduleName]) throw new Error(`${moduleName} module already registered with store`)

  registerSingletonResourceWithStore(
    moduleName,
    'org/formats',
    store,
    {
      state () {
        // Use reasonable defaults until loaded from server.
        // Might consider using moment locale default for date-time formats.
        return {
          originalData: {
            dateFormat: 'mdy',
            fourDigitYear: true,
            dateSeparator: '/',
            dayOfWeek: 'afterdate',
            timeFormat: '12',
            durationFormat: 'minute',
            zeroDurationBlank: false,
            payAccuracy: 2,
            zeroPayBlank: false,
            nameFormat: 'lastfirst',
            displayMiddleName: null,
            punchesPerTimecard: 2,
            calendarEventTitleFormat: 'StartTime_ShiftClassName',
            allColumns: false,
            distanceUnitSystem: 'metric'
          }
        }
      },
      getters: {
        formatName: (state, getters) => (firstName, lastName, middleName, displayName) => {
          if (displayName) return displayName

          const displayedMiddleName = getters.displayedMiddleName(middleName)
          let name = ''

          if (state.originalData.nameFormat === 'lastfirst') {
            name = `${lastName}, ${firstName}`
            if (displayedMiddleName) name += ` ${displayedMiddleName}`
          } else {
            name = `${firstName} `
            if (displayedMiddleName) name += `${displayedMiddleName} `
            name += lastName
          }

          return name
        },

        displayedMiddleName: state => middleName => {
          if (!middleName || !state.originalData.displayMiddleName) return null
          if (state.originalData.displayMiddleName === 'full') return middleName
          else return middleName[0]
        },

        dateFormat: state => {
          return state.originalData.dateFormat
            .split('')
            .join(state.originalData.dateSeparator)
            .replace('m', 'MM')
            .replace('d', 'DD')
            .replace('y', state.originalData.fourDigitYear ? 'YYYY' : 'YY')
        },

        shortDateFormat: state => {
          return state.originalData.dateFormat
            .split('')
            .join(state.originalData.dateSeparator)
            .replace('m', 'M')
            .replace('d', 'D')
            .replace('y', 'YY')
        },

        // used by PrimeVue date picker
        pvDateFormat: state => {
          return state.originalData.dateFormat
            .split('')
            .join(state.originalData.dateSeparator)
            .replace('m', 'mm')
            .replace('d', 'dd')
            .replace('y', state.originalData.fourDigitYear ? 'yy' : 'y')
        },

        // date-fns format used by vue-datepicker
        dfDateFormat: (state, getters) => getters.dateFormat.replace(/D/g, 'd').replace(/Y/g, 'y'),
        dfTimeFormat: (state, getters) => getters.timeFormat.replace('A', 'a'),
        dfDateTimeFormat: (state, getters) => `${getters.dfDateFormat} ${getters.dfTimeFormat}`,

        monthDateFormat: state =>
          state.originalData.dateFormat
            .replace('y', '')
            .split('')
            .join(state.originalData.dateSeparator)
            .replace('m', 'MM')
            .replace('d', 'DD'),

        is12HourFormat: state => state.originalData.timeFormat === '12',

        // used by moment
        timeFormat: (state, getters) => getters.is12HourFormat ? 'hh:mm A' : 'HH:mm',

        dayFormat: () => 'ddd',

        dateTimeFormat: (state, getters) => `${getters.dateFormat} ${getters.timeFormat}`,

        dateDayFormat: (state, getters) => {
          if (state.originalData.dayOfWeek === 'afterdate') return `${getters.dateFormat} ${getters.dayFormat}`
          if (state.originalData.dayOfWeek === 'beforedate') return `${getters.dayFormat} ${getters.dateFormat}`
          return getters.dateFormat
        },

        // The format formats are used for parsing dates and times during bulk import.
        dateFormatsForImport: (state, getters) => [getters.dateFormat, modelDateFormat, moment.ISO_8601],
        dateOptionalTimeFormatsForImport: (state, getters) => [getters.dateTimeFormat, getters.dateFormat, moment.ISO_8601],

        timezone: (state, getters, rootState) => rootState.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone,

        // formatInputDateAsIsoDateTime expects to receive a simple, naive date string,
        // and will localize to timezone while parsing it.
        formatInputDateAsIsoDateTime: (state, getters) => (dt, timezone) => {
          return moment.tz(dt, timezone || getters.timezone).toISOString()
        },

        formatInputDateAsIsoDateTimeExclusiveEnd: (state, getters) => (dt, timezone) => {
          return moment.tz(dt, timezone || getters.timezone).add(1, 'day').toISOString()
        },

        // The following formatting functions typically receive datetime string or objects
        // that are localized, and so they localize them to timezone after parsing them.
        formatDate: (state, getters) => (dt, timezone) => {
          return moment(dt).tz(timezone || getters.timezone).format(getters.dateFormat)
        },

        formatShortDate: (state, getters) => (dt, timezone) => {
          return moment(dt).tz(timezone || getters.timezone).format(getters.shortDateFormat)
        },

        formatNaiveDate: (state, getters) => dt => {
          return moment(dt).format(getters.dateFormat)
        },

        formatNaiveDateDay: (state, getters) => dt => {
          return moment(dt).format(getters.dateDayFormat)
        },

        formatNaiveTime: (state, getters) => dt => {
          return moment(dt).format(getters.timeFormat)
        },

        formatTime: (state, getters) => (dt, timezone) => {
          return getters.formatNaiveTime(moment(dt).tz(timezone || getters.timezone))
        },

        formatNaiveShortTime:  (state, getters) => dt => {
          // In 12 hour format, we try to shorten the format string.
          if (!getters.is12HourFormat) return getters.formatNaiveTime(dt)
          const m = moment(dt)
          const s = m.minutes () === 0 ? m.format('ha') : m.format('h:mma')
          return s.slice(0, -1) // remove 'm' from meridiem
        },

        formatShortTime:  (state, getters) => (dt, timezone) => {
          const m = moment(dt).tz(timezone || getters.timezone)
          return getters.formatNaiveShortTime()
        },

        formatTimeStringAsNaiveTime: (state, getters) => dt => {
          return getters.formatNaiveTime(moment(dt, parseTimeFormat))
        },

        formatTimeStringAsNaiveShortTime: (state, getters) => dt => {
          return getters.formatNaiveShortTime(moment(dt, parseTimeFormat))
        },

        formatMinutesAsShortDuration: (state, getters) => minutes => {
          const hours = round(minutes / 60, 1)
          return `${hours}`
        },

        modelToFormattedTime: (state, getters) => value => {
          return value && moment(value, modelTimeFormat).format(getters.timeFormat)
        },

        formattedToModelTime: (state, getters) => value => {
          return value ? moment(value, getters.timeFormat).format(modelTimeFormat) : null
        },

        formatDateTime: (state, getters) => (dt, timezone) => {
          return moment(dt).tz(timezone || getters.timezone).format(getters.dateTimeFormat)
        },

        timezoneLabel: (state, getters) => (dt, timezone) => {
          const m = moment(dt).tz(timezone || getters.timezone)
          if (!m.isValid()) return null
          // zoneAbbr() seems to be either an alphabetic abbreviation such as 'EST',
          // or a number such as '+08' (Asia/Singapore). In the latter case,
          // we don't want to display "+08 +08:00".
          const hasAbbr = isNaN(Number.parseInt(m.zoneAbbr()))
          return m.format(hasAbbr ? "(z Z)" : '(Z)')
        },

        formatDateTimeTz: (state, getters) => (dt, timezone) => {
          const formattedDt = getters.formatDateTime(dt, timezone)
          const timezoneLabel = getters.timezoneLabel(dt, timezone)
          return timezoneLabel ? `${formattedDt} ${timezoneLabel}` : formattedDt
        },

        formatDateDay: (state, getters) => (dt, timezone) => {
          return moment(dt).tz(timezone || getters.timezone).format(getters.dateDayFormat)
        },

        parseDateTime: (state, getters) => (dtString, timezone) => {
          if (!dtString) return null
          return moment.tz(dtString, getters.dateTimeFormat, timezone || getters.timezone)
        },

        formatMillisAsDuration: state => (millis, zeroDurationBlank, durationFormat) => {
          zeroDurationBlank = zeroDurationBlank === undefined ? state.originalData.zeroDurationBlank : zeroDurationBlank
          durationFormat = durationFormat || state.originalData.durationFormat
          if (!millis && zeroDurationBlank) return ''
          return durationFormat === 'minute'
            ? duration.formatMillisAsClockDuration(millis || 0)
            : duration.formatMillisAsDecimal(millis || 0, durationFormat === 'hundredth' ? 2 : 1)
        },

        formatMinutesAsDuration: (state, getters) => (minutes, zeroDurationBlank, durationFormat) => {
          return getters.formatMillisAsDuration(minutes * 60 * 1000, zeroDurationBlank, durationFormat)
        },

        formatSecondsAsDuration: (state, getters) => (seconds, zeroDurationBlank, durationFormat) => {
          return getters.formatMillisAsDuration(Number.parseFloat(seconds) * 1000, zeroDurationBlank, durationFormat)
        },

        formatPay: state => pay => {
          const parsed = parseFloat(pay) || 0
          if (!parsed && state.originalData.zeroPayBlank) return ''
          return parsed.toFixed(state.originalData.payAccuracy)
        },

        excelPayNumberFormat: state => {
          const payAccuracy = state.originalData.payAccuracy
          return payAccuracy ? `0.${repeat('0', state.originalData.payAccuracy)}` : '0'
        },

        excelDurationNumberFormat: state => durationFormat => {
            switch (durationFormat || state.originalData.durationFormat) {
              case 'decimal':
                return '0.0'
              case 'hundredth':
                return '0.00'
              default:
                return undefined
            }
        },

        formatDistance: state => (meters, precision) => {
          let unitQuantity, unit, quantity
          let shouldInflect = true
          if (state.originalData.distanceUnitSystem === 'imperial') {
            quantity = meters * 3.28084
            unitQuantity = 5280
            unit = quantity < unitQuantity ? 'foot' : 'mile'
          } else {
            quantity = meters
            unitQuantity = 1000
            unit = quantity < unitQuantity ? 'meter' : 'km'
            shouldInflect = unit !== 'km'
          }
          const normalizedQuantity = quantity < unitQuantity ? quantity : quantity / unitQuantity
          const roundedQuantity = round(normalizedQuantity, precision === undefined ? (normalizedQuantity < 10 ? 1 : 0) : precision)
          const unitDisplay = shouldInflect ? inflect(unit, roundedQuantity) : unit
          return `${roundedQuantity} ${unitDisplay}`
        },

        formatSpeed: (state, getters) => (speed, precision = 2) => {
          return `${getters.toPreferredSpeedQuantity(speed, precision)} ${getters.preferredSpeedUnit}`
        },

        toPreferredSpeedQuantity: (state, getters) => (speed, precision = 2) => {
          // speed should be meters per second
          const kph = speed * 3600 / 1000
          const quantity = state.originalData.distanceUnitSystem === 'imperial'
            ? _.round(kph * 0.621371, precision)
            : _.round(kph, precision)
          return quantity
        },

        fromPreferredSpeedQuantity: (state, getters) => (speed, precision = 2) => {
          const multiplier = state.originalData.distanceUnitSystem === 'imperial' ? 1609.34 : 1000
          return _.round(speed * multiplier / 3600, precision)
        },

        preferredSpeedUnit: state => {
          return state.originalData.distanceUnitSystem === 'imperial' ? 'mph' : 'km/h'
        },

        formatElapsed: (state, getters) => elapsed => {
          const tokens = getters.formatElapsedTokens(elapsed)
          if (!tokens) return ''
          return tokens.join(' ')
        },

        formatElapsedTokens: () => elapsed => {
          // elapsed should be seconds
          if (!elapsed) return null
          if (elapsed >= 3600) {
            const hours = Math.floor(elapsed / 3600)
            return [hours, 'h', Math.floor((elapsed - hours * 3600) / 60), 'm']
          } else {
            return [Math.floor(elapsed / 60), 'm', Math.floor(elapsed % 60), 's']
          }
        }
      }
    }
  )

  setTimeout(() => {
    store.watch(
      (state, getters) => getters.orgReady,
      orgReady => {
        if (orgReady) store.dispatch('formatPreferences/load')
      }
    )
  })
}
