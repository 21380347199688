
export function waitForAddedNode(parentNode, queryFn) {
  return new Promise(resolve => {
    const observer = onAddedNode(parentNode, addedNode => {
      const node = queryFn(addedNode)
      if (node) {
        resolve(node)
        observer.disconnect()
      }
    })
  })
}

export function onAddedNode(parentNode, callback) {
  const observer = new MutationObserver(mutationList => {
    for (const mutation of mutationList) {
      for (const addedNode of mutation.addedNodes) {
        callback(addedNode)
      }
    }
  })
  observer.observe(parentNode, {childList: true, subtree: true})
  return observer
}

export function onNodeClassAdded(node, className, callback) {
  const observer = new MutationObserver(mutationList => {
    for (const mutation of mutationList) {
      if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
        if (mutation.target.classList.contains(className)) {
          callback(mutation.target)

        }
      }
    }
  })
  observer.observe(node, {attributes: true})
  return observer
}

export function waitForNodeClassAdded(node, className) {
  return new Promise(resolve => {
    const observer = onNodeClassAdded(node, className, node => {
      resolve(node)
      observer.disconnect()
    })
  })
}

export function unescapeHtml(htmlStr) {
  // https://www.educative.io/answers/how-to-escape-unescape-html-characters-in-string-in-javascript
  return htmlStr
    .replace(/&lt;/g , '<')
    .replace(/&gt;/g , '>')
    .replace(/&quot;/g , '"')
    .replace(/&#39;/g , '\'')
    .replace(/&amp;/g , '&')
}
