<template>
  <ol class="breadcrumb-list">
    <li :key="index" v-for="(item, index) in breadcrumbs">
      <router-link class="breadcrumb-item breadcrumb-home" :to="{name: item.name, params: {orgId: organizationId}}" v-if="isFirst(index)">
        <font-awesome-icon :icon="item.meta.icon" class="icon" /> {{ showName(item) }}
      </router-link>
      <router-link class="breadcrumb-item" :to="{name: item.name, params: {orgId: organizationId}}" v-else-if="!isLast(index) && (!xsBootstrap)">
        <font-awesome-icon :icon="item.meta.icon" class="icon" /> {{ showName(item) }}
      </router-link>
      <span class="breadcrumb-item active" v-else-if="isLast(index) && !smBootstrap">
        <font-awesome-icon :icon="item.meta.icon" class="icon" /> {{ showName(item) }}
      </span>
    </li>
  </ol>
</template>

<script>
import { mapState } from 'vuex'
import constants from '@/constants'

const oConstants = constants()

export default {
  computed: {
    ...mapState(['organizationId', 'organizationName']),
    ...mapState('size', ['clientWidth']),
    xsBootstrap () {
      return this.clientWidth < oConstants.bootstrap.sm
    },
    smBootstrap () {
      return this.clientWidth < oConstants.bootstrap.md
    },
    breadcrumbs () {
      const breadcrumbs = []
      for (let breadcrumb of this.list) {
        breadcrumbs.push(breadcrumb)
        if (breadcrumb.meta.breadcrumbLeaf) {
          break
        }
      }
      return breadcrumbs
    },
    list () {
      // all but first route (home)
      return this.$route.matched.slice(1)
    }
  },
  methods: {
    isFirst (index) {
      return index === 0
    },
    isLast (index) {
      return index === this.list.length - 1
    },
    showName (item) {
      if (item.meta && item.meta.label) {
        item = item.meta && item.meta.label
      }
      if (item.name) {
        item = item.name === 'org-namespace' ? this.organizationName : item.name
      }
      return item
    }
  }
}
</script>
<style lang="scss" scoped>
@use "sass:color";
@import '../assets/scss/vendors/_variables';

// TODO: store shared variables
$menu-icon-width: 50px;
$brand-icon-width: 50px;
$header-dropdown-icon-width: 35px;

$dark: #536c79;
$light: #f5f5f5;
$hot: #20a8d8;

// 1-based index
$odd-text-color: $light;
$odd-background-color: $dark;

$even-text-color: $odd-text-color;
$even-background-color: color.scale($odd-background-color, $lightness: 10%);

$last-text-color: $dark;
$last-background-color: $light;

$hover-background-color: $hot;
$hover-text-color: $light;
$active-background-color: color.scale($hover-background-color, $lightness: -10%);

// reset css in this scope
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 0.875rem;
}

.breadcrumb-list {
  list-style:none;
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;

  li {
    float:left;
    text-wrap: nowrap;

    .breadcrumb-item {
      color: $odd-text-color; // text & icons
      display: inline-block;
      // main rectangular background of odd breadcrumb items
      background: $odd-background-color;
      text-decoration: none;
      position:relative;
      height: 40px;
      line-height:40px;
      padding: 0 10px 0 5px;
      text-align: center;
      margin-right: 23px;

      .icon {
        margin-right: 5px;
      }
    }

    &:nth-child(even):not(:last-child) {
      .breadcrumb-item{
        color: $even-text-color;
        // main rectangular background of even breadcrumb items
        background-color: $even-background-color;

        &:before{
          // left border that fits triangle of item to the left of it
          border-color: $even-background-color;
          // some other shape left of the previous one
          border-left-color: transparent;
        }
        &:after{
        // triangle at right side of even breadcrumbs, excluding last one
          border-left-color: $even-background-color;
        }
      }
    }

    &:first-child{
      .breadcrumb-item{
        padding-left:15px;
        border-radius: 4px 0 0 4px;
        &:before{
          border:none;
         }
      }
    }
    &:last-child{
      .breadcrumb-item{
        color: $last-text-color;
        background-color: $last-background-color;

        padding-right:15px;
        border-radius: 0 4px 4px 0;
        &:after{
          border:none;
        }
        &:active {
          color: $light;
        }
      }
    }

    .breadcrumb-item {
      &:before,
      &:after{
        content: "";
        position:absolute;
        top: 0;
        // left end of the last breadcrumb item that fits into triangle
        border: 0 solid $last-background-color;
        border-width:20px 10px;
        width: 0;
        height: 0;
      }
      &:before{
        left:-20px;
        border-left-color:transparent;
      }
      &:after{
        left:100%;
        border-color:transparent;
        // triangle at right side of odd breadcrumbs, excluding last one
        border-left-color: $odd-background-color;
      }
      &:active{
        background-color: $active-background-color;

        &:before{
          border-color:$active-background-color;
          border-left-color:transparent;
         }
        &:after{
          border-left-color:$active-background-color;
        }
      }
    }

    a.breadcrumb-item:hover, &:nth-child(even):not(:last-child) a.breadcrumb-item:hover {
        background-color: $hover-background-color;
        color: $hover-text-color;

        &:before{
          border-color:$hover-background-color;
          border-left-color:transparent;
         }
        &:after{
          border-left-color:$hover-background-color;
        }
    }
  }

  // prevent explosion of home item when org name is very long
  .breadcrumb-home {
    max-width: 400px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @include media-breakpoint-down(lg) {
    .breadcrumb-home {
      max-width: 300px;
    }
  }

  @include media-breakpoint-down(md) {
    .breadcrumb-home {
      max-width: 150px;
    }
  }
}
</style>
