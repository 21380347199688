import loadjs from 'loadjs'
import { onAddedNode, waitForAddedNode } from '@/utils/dom-utils.js'
import _ from 'lodash'

let domObserver

export function openZendesk (store) {
  runZendeskCommand('open', store)
}

export function toggleZendesk (store) {
  runZendeskCommand('toggle', store)
}

function runZendeskCommand (command, store) {
  loadZendesk(store)
    .then(() => window.zE('webWidget', command))
    .catch(e => {
      console.warn('Failed to load zendesk', e)
      throw e
    })
}

function loadZendesk (store) {
  if (loadjs.isDefined('zendesk')) return Promise.resolve()
  return loadjs([
    'https://static.zdassets.com/ekr/snippet.js?key=f212f022-ffd0-4615-b93e-245823209913',
  ], 'zendesk', {
    numRetries: 3,
    returnPromise: true,
    before: function (path, scriptEl) {
      scriptEl.id = 'ze-snippet'
    }
  })
    .then(() => initZendesk(store))
}

function initZendesk (store) {
  return new Promise(resolve => {
    pollAndInitZendesk(store, resolve)
  })
}

function pollAndInitZendesk (store, resolve) {
  if (window.zE) {
    // Hide the Zendesk web widget launcher button, because we have a custom button to launch it.
    window.zE('webWidget', 'hide')
    window.zE(function () {
      window.zE.identify({
        name: store.getters['jwt/displayName'],
        email: store.getters['jwt/email'],
        organization: store.state.organizationName
      })
    })
    window.zE('webWidget:on', 'open', () => {
      window.zE('webWidget', 'show')
      setTimeout(() => customizeWebWidget(), 50)
    })
    window.zE('webWidget:on', 'close', () => {
      window.zE('webWidget', 'hide')
      if (domObserver) {
        domObserver.disconnect()
        domObserver = null
      }
    })
    resolve()
  } else {
    setTimeout(() => pollAndInitZendesk(store, resolve), 20)
  }
}

function customizeWebWidget (invocationCount = 0) {
  // Hide Zendesk logo.
  // Zendesk seems to play games to prevent hiding the logo, so we try to work around it.
  const webWidget = document.querySelector('iframe#webWidget');
  (webWidget
    ? Promise.resolve(webWidget)
    : waitForAddedNode(document, child => child.nodeName === 'IFRAME' && child.id === 'webWidget' ? child : null)
  ).then(webWidget => {
    const widgetDocument = webWidget.contentWindow.document
    const zendeskLogo = widgetDocument.querySelector('footer a');
    (zendeskLogo
      ? Promise.resolve(zendeskLogo)
      : waitForAddedNode(widgetDocument, addedNode => findZendeskLogo(addedNode))
    ).then(zendeskLogo => {
      zendeskLogo.style.visibility = 'hidden'
      if (invocationCount < 2) {
        setTimeout(() => customizeWebWidget(invocationCount + 1), 50)
      } else {
        // As user navigates the widget, it may swap new content with a new footer.
        // So keep checking and customizing.
        // A mutation observer is faster than using the Zendesk API webWidget:on event observer.
        const parentNode = widgetDocument.querySelector('div#Embed')
        if (parentNode) {
          domObserver = onAddedNode(parentNode, node => {
            const zendeskLogo = findZendeskLogo(node)
            if (zendeskLogo) zendeskLogo.style.visibility = 'hidden'
          })
        }
      }
    })
  })
}

function findZendeskLogo (node) {
  if (_.isString(node.className) && node.className.startsWith('ZendeskLogo__Link')) return node
  else {
    // When user types in textarea for web widget contact us screen, this function gets called
    // with node as a Text object that doesn't have querySelector function.
    const zendeskLogo = node.querySelector && node.querySelector('footer a')
    return zendeskLogo
  }
}
