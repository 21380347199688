<template>
  <img class="face-log-photo"
    :src="srcUrl"
    :key="faceLogId"
    @load="onLoad"
  />
</template>
<script>
import { mapState } from 'vuex'
import restClient from '@/services/clients/rest'

export default {
  name: 'FaceLogPhoto',
  props: {
    faceLogId: Number
  },
  data () {
    return {
      // TODO: maybe we should store a formal orgRestBaseURL in vuex?
      baseURL: restClient.defaults.baseURL
    }
  },
  computed: {
    ...mapState(['organizationId']),
    srcUrl () {
      return this.faceLogId
        ? `${this.baseURL}/orgs/${this.organizationId}/facelogs/${this.faceLogId}/photo`
        : '/img/stock-face.png'
    }
  },
  methods: {
    onLoad () {
      this.$emit('load')
    }
  }
}
</script>
<style lang="scss" scoped>
.face-log-photo {
  max-width: 120px;
  max-height: 160px;
  border: 1px solid #ccc;
  border-radius: 6px;
}
</style>
