<template>
  <div></div>
  <!--<div class="sidebar-header"></div>-->
</template>
<script>

export default {
  name: 'sidebar-header'
}
</script>
